/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

import React, { useMemo, useContext, useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { message, Checkbox, Segmented, Tooltip } from 'antd';

import * as ASConfig from './ASConfig';
import { AppContext, Constants } from '../../Utils';
import { useVMSGraphql } from '../VMS/VMSGraphql'
import SVGIcon from '../../icons.js';

const gvCloudUrl = process.env.REACT_APP_URL_BASE;
const loginUrl = `${process.env.REACT_APP_URL_ASCLOUD_API}login`;
const webSocketUrl = process.env.REACT_APP_URL_WEBSOCKET;

const enumServicePath = {
    [ASConfig.EnumServiceType.Access]:   'access',
    [ASConfig.EnumServiceType.Map]:      'map'
};

export default class ASUtils extends React.Component {

    _wsKeepAliveTime = (window.chrome ? 8.5 : 9.5) * 60 * 1000; // Websocket will be disconnected when no transmission for more than 10 minutes. (When web is invisible for more than 5 minutes, Chrome will start throttling, with a maximum delay of 1 minute.)
    _wsReconnectTime = 115 * 60 * 1000; // Websocket will be disconnected by AWS for every 2 hours.
    _wsMaxErrorCount = 20;
    _wsDelayTolerateTime = 1 * 60 * 1000; // Edge will going to sleep mode when the page inactived, Cause js not work
    _ws = null;
    _wsKeepAliveTimer = null;
    _wsErrorCount = 0;
    _wsFirstConnect = true;
    _wsReconnectTimer = null;
    _wsPositiveDisconnect = false;
    _wsReloadPage = false;

    constructor(props) {
        super(props);

        this.state = {
            wsClientId: null,

            debugMsg: this.debugMsg,
            ajaxLogin: this.ajaxLogin,
            sendWS: this.sendWS,
            getWsClientId: this.getWsClientId
        };
    }

    componentDidMount() {
        var clientId = window.sessionStorage.getItem('clientid');
        if (!clientId) {
            var strDate = new Date().getTime().toString(16);
            strDate = strDate.substring(strDate.length - 6);
            clientId = `${this.props.serviceType + (process.env.REACT_APP_TYPE === 'dev' ? 100 : 0)}_${Math.round(Math.random() * 1000).toString(16)}${strDate}`;
            window.sessionStorage.setItem('clientid', clientId);
        }
        this.setState({wsClientId: clientId});

        if (process.env.REACT_APP_TYPE === 'dev') {
            const _self = this;
            const getState = () => {
                if (document.visibilityState === 'hidden') {
                    return 'hidden';
                }
                if (document.hasFocus()) {
                    return 'focused';
                }
                return 'not focused';
            };

            _self.bsState = getState();
            const logStateChange = (nextState) => {
                if (nextState !== _self.bsState) {
                    _self.debugMsg('BSState', `State changed from '${_self.bsState}' to '${nextState}'`);
                    _self.bsState = nextState;
                }
            };

            ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
                window.addEventListener(type, () => {logStateChange(getState())}, {capture: true});
            });
            window.addEventListener('freeze', () => {logStateChange('frozen')}, {capture: true});

            function onPageHide(event) {
                if (event.persisted) {
                  // If the event's persisted property is `true` the page is about
                  // to enter the page navigation cache, which is also in the frozen state.
                  logStateChange('frozen');
                } else {
                  // If the event's persisted property is not `true` the page is about to be unloaded.
                  logStateChange('terminated');
                }
            }
            window.addEventListener('pagehide', onPageHide, {capture: true});
        }
    }

    componentWillUnmount() {
        clearTimeout(this._wsKeepAliveTimer);
        clearTimeout(this._wsReconnectTimer);
        if (this._ws) {
            this._ws.onopen = null;
            this._ws.onmessage = null;
            this._ws.onclose = null;
            this._ws.onerror = null;
            this._ws.close(1000);
        }
    }

    debugMsg = (module, msg) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            const padZero = (val, length) => {
                return val.toString().padStart(length || 2, '0');
            };
            var now = new Date(),
                time = `${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}.${padZero(now.getMilliseconds(), 3)}`;
            console.log(`${time} [${module}]: ${msg}`);
        }
    };

    ajaxLogin = (callback) => {
        callback = callback || function(){};

        fetch(loginUrl, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.props.token
            }),
            body: JSON.stringify({
                // service_type: this.props.serviceType,
                device_type: 101,   // (1: AS, 2: LPR, 101: Browser, 102: Mobile)
            })
        })
        .then(res => res.json())
        .then(data => {
            if (!data.errcode) {
                callback(true, data.data[0]);
                if (!this._ws) {
                    this._connectWS();
                }
            } else if (data.errcode === ASConfig.EnumErrorCode.ERR_LOGIN_FAILED) {
                window.location = `${gvCloudUrl}?redirect=/${enumServicePath[this.props.serviceType]}&pathname=${window.location.pathname}`;
            } else {
                callback(false, ASConfig.EnumErrorMessage[data.errcode] ? data.errcode : data.errmsg);
            }
        })
        .catch(e => {
            callback(false, e.toString());
            console.log(e);
        });
    };

    /* -------- WebSocket Start -------- */
    _debugMsgWS = (msg, send) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            var strSend = send ? 'Send' : (send === false ? 'Receive' : '');
            var strCmd = '';

            var getStatus = (enumStatus, status) => {
                var arrStatus = [];
                Object.keys(enumStatus).forEach(key => {
                    if (key !== 'NONE' && (status & enumStatus[key]) === enumStatus[key]) {
                        arrStatus.push(key);
                    }
                });
                if (arrStatus.length > 0) {
                    return ` (${arrStatus.join(' / ')})`;
                }
                return '';
            };
            try {
                var data = JSON.parse(msg);
                if (data.cmd_id) {
                    if (data.cmd_id === -1) {
                        strCmd = 'KeepAlive';
                    } else {
                        strCmd = Object.keys(ASConfig.EnumWebSocketCmd).find(key => ASConfig.EnumWebSocketCmd[key] === data.cmd_id);
                    }

                    if (data.cmd_id === ASConfig.EnumWebSocketCmd.DEVICE_STATUS_CHANGED) {
                        strCmd += getStatus(ASConfig.EnumDeviceStatus, parseInt(data.data2));
                    } else if (data.cmd_id === ASConfig.EnumWebSocketCmd.GATE_STATUS_CHANGED) {
                        strCmd += getStatus(ASConfig.EnumGateStatus, parseInt(data.data2));
                    }
                }
            } catch {}

            this.debugMsg('ASCloudSocket', `${strSend}: ${strCmd || ''}, ${msg}`);
        }
    };

    _connectWS = (reconnect) => {
        var _self = this,
            _ws;

        const clearWebsocket = (ws, code) => {
            ws.onopen = null;
            ws.onmessage = null;
            ws.onclose = null;
            ws.onerror = null;
            ws.close(code);
            ws = null;
        };

        if (!reconnect && _self._ws) {
            clearWebsocket(_self._ws);
        }

        _self._wsPositiveDisconnect = true;
        _ws = new WebSocket(`${webSocketUrl}?p1=${this.props.token}&p2=${this.state.wsClientId}`);
        
        _ws.onopen = function(e) {
            _self._debugMsgWS('Open');
            _self._wsErrorCount = 0;

            if (reconnect && _self._ws) {
                clearWebsocket(_self._ws, 1000);
            }
            _self._ws = _ws;
            _self._wsPositiveDisconnect = false;
            
            if (!_self._wsFirstConnect) {   // if reconnect websocet, then get all device
                var notifyData = {cmd_id: ASConfig.EnumWebSocketCmd.RECONNECT_WEBSOCKET};
                _self.props.onNotify(notifyData);
            }
            _self._wsFirstConnect = false;
        };

        _ws.onmessage = function(e) {
            _self._debugMsgWS(e.data, false);
            _self._keepWSAlive();
            var data = {};
            try {
                data = JSON.parse(e.data);
            } catch {}

            if (_self._wsReloadPage) {
                return;
            }
            
            if (data.un_time && (new Date().getTime() - data.un_time * 1000 > _self._wsDelayTolerateTime)) {
                if (process.env.REACT_APP_TYPE === 'dev') {
                    const msg = `websocket onmessage over tolerate time, so Reload Page. message time: ${data.un_time}, get time: ${new Date().getTime()}`;
                    _self.debugMsg('Reload', msg);
                    alert(msg);
                }
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self.props.onNotify(data);
        };

        _ws.onclose = function(e) {
            // If login with same account in Dev and Stage environment, all Websocket connections in the other environment will be deleted.
            if (!_self._wsPositiveDisconnect) { // if not possive to discconet, then reload page
                if (e.code === 1006) {
                    _self.debugMsg('Reload', `websocket closed abnormally. wsClientId: ${_self.getWsClientId()}. code: ${e.code}`);
                    var notifyData = {cmd_id: ASConfig.EnumWebSocketCmd.ABNORMAL_CLOSE_WEBSOCKET};
                    _self.props.onNotify(notifyData);
                    return;
                }

                if (process.env.REACT_APP_TYPE === 'dev') {
                    _self.debugMsg('Reload', `websocket closed not possive, so Reload Page. wsClientId: ${_self.getWsClientId()}, code: ${e.code}`);
                    alert(`websocket closed not possive, so Reload Page. wsClientId: ${_self.getWsClientId()}, code: ${e.code}`);
                }
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self._ws = null;
            _self._debugMsgWS(`Close, wasClean: ${e.wasClean}, code: ${e.code}, reason: ${e.reason}, error count: ${_self._wsErrorCount}`);

            if (_self._wsErrorCount < _self._wsMaxErrorCount) {
                setTimeout(() => {
                    _self._connectWS();
                }, _self._wsErrorCount * 1000);
            } else {
                window.location = `${gvCloudUrl}?redirect=/${enumServicePath[_self.props.serviceType]}&pathname=${window.location.pathname}`;
            }
        };

        _ws.onerror = function(e) {
            _self._ws = null;
            _self._debugMsgWS(`Error, error count: ${_self._wsErrorCount}, message: ${e.message}`);
            _self._wsErrorCount++;
        };

        _self._keepWSAlive();
        
        clearTimeout(_self._wsReconnectTimer);
        _self._wsReconnectTimer = setTimeout(() => {
            _self._connectWS(true);
        }, _self._wsReconnectTime);
    };

    _keepWSAlive = () => {
        clearTimeout(this._wsKeepAliveTimer);
        this._wsKeepAliveTimer = setTimeout(this.sendWS, this._wsKeepAliveTime, {cmd_id: -1});
    };

    sendWS = (params) => {
        if (this._ws) {
            params = {...params, action: "message"};
            var str = JSON.stringify(params);
            this._ws.send(str);
            this._debugMsgWS(str, true);
            this._keepWSAlive();
        }
    };
    /* -------- WebSocket End -------- */

    getWsClientId = () => {
        return this.state.wsClientId;
    };

    render() {
        return null;
    }
}
ASUtils.defaultProps = {
    serviceType: ASConfig.EnumServiceType.Access,
    token: '',
    onNotify: function(data) {}
};

export const SegmentedEx = ({className, ...props}) => {
    const handleClick = (e) => {
        if (!e.target || !e.target.classList.contains('ant-segmented-item-input')) return;

        const segmentedItem = e.target.closest('.ant-segmented-item');
        if (!segmentedItem) return;

        const segmentedLabel = segmentedItem.querySelector('.ant-segmented-item-label');
        if (!segmentedLabel) return;

        const animatingTime = 300;
        segmentedLabel.classList.add('activing');
        setTimeout(() => {
            const start = new Date();
            const checkSelected = setInterval(() => {
                if (segmentedItem.classList.contains('ant-segmented-item-selected') || (new Date() - start) > animatingTime * 2) {
                    segmentedLabel.classList.remove('activing');
                    clearInterval(checkSelected);
                }
            }, 50);
        }, animatingTime);
    };

    return (
        <Segmented className={`and-segmented-ex ${className}`} onClick={handleClick} {...props} />
    );
};

export const isWinPlatform = (window.navigator?.userAgentData?.platform || window.navigator.platform).toString().includes('Win');

export const FormatedTime = function({timeFormat, localtime, utctime, dst, fixedTime, ...props}) {
    const { accountInfo } = useContext(AppContext);
    
    const time_format = useMemo(() => {
        return timeFormat || accountInfo.time_format || 'MM-DD-YYYY HH:mm:ss';
    }, [timeFormat, accountInfo.time_format]);

    const countdownTime = useMemo(() => {
        if (!props.children || fixedTime || !utctime) return '';

        var log_utc_time = new Date(utctime),
            t = new Date(),
            currentUTC = t.getTime() + (t.getTimezoneOffset() * 60000);
        var diff = currentUTC - log_utc_time.getTime();
        
        if (diff < 45 * 1000) {
            return <FormattedMessage id='seconds_ago' defaultMessage='a few seconds ago' />;
        } else if (diff < 59 * 60 * 1000) {
            var mins = Math.round(diff / (60 * 1000));
            if (mins === 1) {
                return <FormattedMessage id='minute_ago' defaultMessage='1 minute ago' />;
            } else {
                return <FormattedMessage id="minutes_ago_format" defaultMessage="{0} minutes ago" values={{0: `${mins}`}} />;
            }
        }
        
        return '';
    }, [props.children, fixedTime, utctime]);

    const time = useMemo(() => {
        var strTime = moment(new Date(localtime)).format(time_format);
        if (dst === ASConfig.EnumLogDSTType.InDSTOverlay) {
            strTime += ' (DST)';
        }
        return strTime;
    }, [localtime, time_format, dst]);

    if (props.children) {
        return props.children(time, countdownTime || time);
    } else {
        return time;
    }
};
FormatedTime.defaultProps = {
    timeFormat: '',
    localtime: '',
    utctime: '',
    dst: ASConfig.EnumLogDSTType.None,
    fixedTime: false,
};

export function GVJsPlayerHeader({onLoad}) {
    const [jsCount, setJsCount] = useState(0);
    useEffect(() => {
        if (!document.head.querySelector('link[href="css/gvplayer.css"]')) {
            var linkCss = document.createElement('link');
            linkCss.type = 'text/css';
            linkCss.rel = 'stylesheet';
            linkCss.href = 'css/gvplayer.css';
            document.head.append(linkCss);
        }

        if (!document.head.querySelector('script[src="js/manifest.min.js"]')) {
            var scriptManifest = document.createElement('script');
            scriptManifest.type = 'text/javascript';
            scriptManifest.src = 'js/manifest.min.js';
            scriptManifest.addEventListener('load', () => {
                setJsCount(current => current + 1);
            });
            document.head.append(scriptManifest);
        } else {
            setJsCount(current => current+1);
        }

        if (!document.head.querySelector('script[src="js/gvplayer.min.js"]')) {
            var scriptGvplayer = document.createElement('script');
            scriptGvplayer.type = 'text/javascript';
            scriptGvplayer.src = 'js/gvplayer.min.js';
            scriptGvplayer.addEventListener('load', () => {
                setJsCount(current => current + 1);
            });
            document.head.append(scriptGvplayer);
        } else {
            setJsCount(current => current + 1);
        }
    }, []);
    useEffect(() => {
        if (jsCount === 2) {
            const check = () => {
                if (window.GvPlayer) {
                    onLoad();
                } else {
                    setTimeout(check, 50);
                }
            };
            check();
        }
    }, [jsCount, onLoad]);
    return null;
}
GVJsPlayerHeader.defaultProps = {
    onLoad: function() {}
};

export function GVWsPlayerHeader({onLoad}) {
    const [jsCount, setJsCount] = useState(0);
    useEffect(() => {
        if (!document.head.querySelector('link[href="css/wsplayer.css"]')) {
            var linkCss = document.createElement('link');
            linkCss.type = 'text/css';
            linkCss.rel = 'stylesheet';
            linkCss.href = 'css/wsplayer.css';
            document.head.append(linkCss);
        }

        if (!document.head.querySelector('script[src="js/wsplayer.min.js"]')) {
            var scriptWsplayer = document.createElement('script');
            scriptWsplayer.type = 'text/javascript';
            scriptWsplayer.src = 'js/wsplayer.min.js';
            scriptWsplayer.addEventListener('load', () => {
                setJsCount(current => current + 1);
            });
            document.head.append(scriptWsplayer);
        } else {
            setJsCount(current => current + 1);
        }
    }, []);

    useEffect(() => {
        if (jsCount === 1) {
            const check = () => {
                if (window.WsPlayer) {
                    onLoad();
                } else {
                    setTimeout(check, 50);
                }
            };
            check();
        }
    }, [jsCount, onLoad]);

    return null;
}
GVWsPlayerHeader.defaultProps = {
    onLoad: function() {}
};

const liveMaxIdleTime = 5 * 60 * 1000;  // Live Video Idle Time (millisecond)
export const LiveVideoIdle = ({playerStatus, onIdle, onActive, ...props}) => {
    const [playerRealStatus, setPlayerRealStatus] = useState(false);

    useEffect(() => {
        setPlayerRealStatus(playerStatus);
    }, [playerStatus]);

    const handleIdle = () => {
        if (playerRealStatus) {
            onIdle();
            console.log('Player Idle.');
            setTimeout(() => {
                setPlayerRealStatus(true);
            }, 300);
        }
    };

    const handleAcive = () => {
        if (playerRealStatus) {
            onActive();
            console.log('Player Active.');
        }
    };

    useIdleTimer({
        onIdle: handleIdle,
        onActive: handleAcive,
        timeout: liveMaxIdleTime
    });

    return null;
};
LiveVideoIdle.defaultProps = {
    playerStatus: false,
    onIdle: function() {},
    onActive: function() {}
};

const GVJsPlayer = forwardRef(({divId, videoMode, active, pauseLive, connectParams, onPlayChange, ...props}, ref) => {
    const { debugMsg } = useContext(AppContext);
    const [isJSLoaded, setIsJSLoaded] = useState(false);
    const [player, setPlayer] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [conntected, setConnected] = useState(false);

    useEffect(() => {
        if (isJSLoaded && window.GvPlayer && active && !player && connectParams) {
            const _player = window.GvPlayer(videoMode);
            _player.init({
                divId: divId,
                buffer: 300, // default
                autoReconnect: true, // default
                debug: false, // default: false
                showControls: true,
                enableFullscreen: true,
                enableContextMenu: true
            });

            _player.resize();
            _player.on('playChange', (isPlaying) => {setPlaying(isPlaying); setConnected(true);});

            setPlayer(_player);
        }
    }, [isJSLoaded, active, player, videoMode, divId, onPlayChange, connectParams]);

    useEffect(() => {
        if (player) {
            try {
                player.disconnect();
                setConnected(false);

                if (connectParams && typeof(connectParams) === 'object') {
                    debugMsg('GVJsPlayer', `connParam: ${JSON.stringify(connectParams)}`);
                    player.connect(connectParams).resize();
                    setTimeout(() => {
                        player.resize();
                    }, 500);
                    setPlaying(true);
                }
            } catch {}
        }
    }, [player, connectParams, debugMsg]);

    useEffect(() => {
        onPlayChange(playing);
    }, [playing, onPlayChange]);

    useEffect(() => {
        return () => {
            if (player) {
                console.log('destroy');
                player.disconnect();
                player.destroy();
            }
        };
    }, [player]);

    useEffect(() => {
        if (player && conntected) {
            if (active && !pauseLive && !playing) {
                player.play();
            } else if ((!active || pauseLive) && playing) {
                player.pause();
            }
        }
    }, [player, conntected, active, pauseLive, playing]);

    useImperativeHandle(ref, () => ({
        play: () => {
            if (player) player.play();
        },
        pause: () => {
            if (player) player.pause();
        },
        resize: () => {
            if (player) player.resize();
        }
    }));

    return (
        <>
            <GVJsPlayerHeader onLoad={() => setIsJSLoaded(true)} />
            <div id={divId}></div>
            {
                player && videoMode === 'live' &&
                <LiveVideoIdle playerStatus={playing} onIdle={() => player.stop()} onActive={() => player.play()} />
            }
        </>
    );
});

export const WsPlayerDetector = ({onSupport}) => {
    const [isJSLoaded, setIsJSLoaded] = useState(false);

    useEffect(() => {
        if (isJSLoaded && window.WsPlayer) {
            const init = async () => {
                const _player = window.WsPlayer('live');
                const _isReady = await _player.waitReady();
                var version = 0;
                if (_isReady) version = _player.getVersion();
                _player.destroy();
                onSupport(_isReady, version);
            };
            init();
        }
        onSupport(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJSLoaded]);

    return (
        <GVWsPlayerHeader onLoad={() => setIsJSLoaded(true)} />
    );
};
WsPlayerDetector.defaultProps = {
    onSupport: function(flag, version) {}
};

const GVWsPlayer = forwardRef(({divId, videoMode, active, pauseLive, connectParams, onPlayChange, onNotSupport, intercoms, onBypass, ...props}, ref) => {
    const { debugMsg } = useContext(AppContext);
    const [isJSLoaded, setIsJSLoaded] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [player, setPlayer] = useState(null);
    const [playing, setPlaying] = useState(false);

    const playerDivId = useMemo(() => {
        return `${divId}-wsplayer`;
    }, [divId]);

    useEffect(() => {
        if (isJSLoaded && active && !isInit && connectParams) {
            setIsInit(true);
            const init = async () => {
                const dt = new Date();
                const _player = window.WsPlayer(videoMode);
                const isReady = await _player.waitReady();
                if (!isReady) {
                    onNotSupport();
                    return;
                }
    
                await _player.init({
                    divId: playerDivId,
                    autoReconnect: true,
                    showControls: true,
                    enableFullscreen: true,
                    enableContextMenu: true,
                    split: 1,
                    bgcolor:'#404247',
                    showTimeline: true  // playback mode only
                });

                console.log(`[GVWsPlayer] init time: ${new Date() - dt} ms`);

                _player.on('byPass', onBypass);

                _player.resize();
                setPlayer(_player);
            };
            init();
        }
    }, [isJSLoaded, active, isInit, connectParams, videoMode, playerDivId, onNotSupport, onBypass]);

    useEffect(() => {
        if (player) {
            try {
                player.disconnect(0);
                debugMsg('GVWsPlayer', `disconnect`);

                if (connectParams && typeof(connectParams) === 'object') {
                    debugMsg('GVWsPlayer', `connParam: ${JSON.stringify(connectParams)}`);
                    player.connect(connectParams, 0);
                    player.resize();
                    player.setSplitIdx(0);
                    // player.play();
                    setTimeout(() => {
                        setPlaying(true);    
                    }, 1000);
                }
            } catch {}
        }
    }, [player, connectParams, debugMsg]);

    useEffect(() => {
        return () => {
            if (player) {
                player.disconnect(0);
                player.destroy();
                debugMsg('GVWsPlayer', `disconnect by destroy player`);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player]);

    useEffect(() => {
        if (player && videoMode === 'playback') {
            if (active) {
                player.play();
            } else {
                player.pause();
            }
        }
    }, [player, active, videoMode]);

    useEffect(() => {
        if (player && intercoms && playing) {
            player.bypass(0, intercoms);
            debugMsg('GVWsPlayer', `byPass: ${JSON.stringify(intercoms)}`);
        }
    }, [player, intercoms, playing, debugMsg]);

    useEffect(() => {
        if (player) {
            if (pauseLive) {
                player.dragStart();
            } else {
                player.dragEnd();
            }
        }
    }, [player, pauseLive]);

    useImperativeHandle(ref, () => ({
        play: () => {
            if (player && videoMode === 'playback') player.play();
        },
        pause: () => {
            if (player && videoMode === 'playback') player.pause();
        },
        resize: () => {
            if (player) player.resize();
        }
    }));

    return (
        <>
            <GVWsPlayerHeader onLoad={() => setIsJSLoaded(true)} />
            <div id={playerDivId}></div>
            {
                player && videoMode === 'live' &&
                <LiveVideoIdle playerStatus={playing} onIdle={() => player.pause()} onActive={() => player.play()} />
            }
        </>
    );
});
GVWsPlayer.defaultProps = {
    onNotSupport: function() {},

    intercoms: null, // {showPickupBtn, showHandupBtn, showDoorBtn} // showDoorBtn 0: hidden, 1:door lock , 2:door unlock
    onBypass: function() {}
};

const isSameObject = (a, b) => {
    if (!a || !b || typeof(a) !== 'object' || typeof(b) !== 'object' || Object.keys(a).length !== Object.keys(b).length) return false;
    return Object.entries(a).every(([key, value]) => b[key] === value);
};
export const GVPlayer = forwardRef(({playType, connectParams, connectCloudParams, onWSPlayerNotSupport, ...props}, ref) => {
    const { wsPlayerConfig } = useContext(AppContext);
    const [notSupportWsPlayer, setNotSupportWsPlayer] = useState(false);
    const [newConnectParams, setNewConnectParams] = useState(null);
    const playerRef = useRef();

    const playerType = useMemo(() => {
        return isWinPlatform && wsPlayerConfig.isSupport && !notSupportWsPlayer && props.videoMode === 'live' && (props.isDoorBell || !wsPlayerConfig.disabled) ? 'ws' : 'js';
    }, [wsPlayerConfig, notSupportWsPlayer, props.videoMode, props.isDoorBell]);

    useEffect(() => {
        if (connectParams || connectCloudParams) {
            var _newConnectParams;
            if (playerType === 'js') {
                if (playType === ASConfig.EnumPlaybackType.Cloud && connectCloudParams) {
                    _newConnectParams = connectCloudParams;
                } else {
                    _newConnectParams = connectParams;
                }
            } else {
                if (props.videoMode === 'live') {
                    _newConnectParams = {
                        ...connectParams,
                        port: 80,
                        gvcloudHostType: connectParams.host_type || 9
                    };
                } else {
                    _newConnectParams = {
                        ...connectParams,
                        port: 80
                    };
                }
            }

            if (!isSameObject(_newConnectParams, newConnectParams)) {
                setNewConnectParams(_newConnectParams);
            }
        }
    }, [newConnectParams, connectParams, connectCloudParams, props.videoMode, playType, playerType]);

    useImperativeHandle(ref, () => ({
        play: () => {
            if (playerRef.current) playerRef.current.play();
        },
        pause: () => {
            if (playerRef.current) playerRef.current.pause();
        },
        resize: () => {
            if (playerRef.current) playerRef.current.resize();
        }
    }));

    return (
        playerType === 'ws' ?
        <GVWsPlayer ref={playerRef} {...props} connectParams={newConnectParams} onNotSupport={() => setNotSupportWsPlayer(true)} />
        :
        <GVJsPlayer ref={playerRef} {...props} connectParams={newConnectParams} />
    );
});
GVPlayer.defaultProps = {
    divId: '',
    videoMode: 'live',  // live | playback,
    playType: ASConfig.EnumPlaybackType.Local,
    active: false,
    pauseLive: false,
    connectParams: null,
    connectCloudParams: null,
    onPlayChange: function() {},

    isDoorBell: false,
    intercoms: null, // only for wsplayer {showPickupBtn, showHandupBtn, showDoorBtn} // showDoorBtn 0: hidden, 1:door lock , 2:door unlock
    onBypass: function() {}
};

export const PlayerTypeSegmented = () => {
    const { wsPlayerConfig, disabledWSPlayer } = useContext(AppContext);

    const handleChange = (val) => {
        disabledWSPlayer(val === 'js');
    };

    return (
        isWinPlatform && wsPlayerConfig.isSupport &&
        <SegmentedEx className='player-type-segmented' value={wsPlayerConfig.disabled ? 'js' : 'ws'} onChange={handleChange}
            options={[{
                value: 'ws',
                icon: (
                    <Tooltip title={<FormattedMessage id='ws_player' />}>
                        <SVGIcon.WSplayer />
                    </Tooltip>
                )
            }, {
                value: 'js',
                icon: (
                    <Tooltip title={<FormattedMessage id='js_player' />}>
                        <SVGIcon.JSplayer />
                    </Tooltip>
                )
            }]}
        />
    );
};

export const utc2localtime = (strUTC) => {
    var date = new Date(moment(strUTC).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
    return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
};

export const timeScaleToStr = (num) => {    // num: 0 ~ 288
    var mins = num * 5;
    return Math.floor(mins/60).toString().padStart(2, '0') + ':' + (mins%60).toString().padStart(2, '0');
};

export const strToTimeScale = (str) => {    // str: 23:45
    if (str.includes(':')) {
        var [hour, min] = str.split(':');
        return (parseInt(hour) * 60 + parseInt(min)) / 5;
    }
    return 0;
};

export const timeScaleToHex = (strScale) => {
    var hexSize = 32, hexLength = 8;
    var strHex = '', i;

    for (i = 0; i < ASConfig.timezoneScaleCount; i = i + hexSize) {
        strHex += parseInt(strScale.substr(i, hexSize), 2).toString(16).padStart(hexLength, '0');
    }

    return strHex;
};

export const hexToTimeScale = (strHex) => {
    var scale = '';
    var hexSize = 32, hexLength = 8;

    var hexTotalLength = ASConfig.timezoneScaleCount / (hexSize / hexLength) , i;

    if (strHex.length === hexTotalLength) {
        for (i = 0; i < hexTotalLength; i = i + hexLength) {
            scale += parseInt(strHex.substr(i, hexLength), 16).toString(2).padStart(hexSize, '0');
        }
    } else {
        scale = getEmptyTimeScale();
    }

    return scale;
};

export const getEmptyTimeScale = (type) => {
    return Array(ASConfig.timezoneScaleCount).fill(type || 0).join('');
};

export const defaultSnapshotsSatuses = {
    ver: '',
    ca: [],
    ve: [],
    fa: []
};
export const setLogSnapshotsSatuses = (log) => {
    var valid = false,
        ss_statuses;
    if (log.ss_statuses) {
        if (typeof(log.ss_statuses) === 'string') {
            try {
                ss_statuses = JSON.parse(log.ss_statuses);
                ss_statuses = {
                    ...defaultSnapshotsSatuses,
                    ...ss_statuses
                };
                valid = true;
            } catch (e) {}
        } else if (typeof(log.ss_statuses) === 'object') {
            ss_statuses = {
                ...defaultSnapshotsSatuses,
                ...log.ss_statuses
            };
            valid = true;
        }
    }

    if (!valid) {
        ss_statuses = {...defaultSnapshotsSatuses};
        log.factors = ASConfig.EnumFactorType.Card;
        log.m_factor = ASConfig.EnumMajorFactor.Card;
        
        if (log.v_no) {
            ss_statuses.va = [log.snapshot_status];
            log.factors = ASConfig.EnumFactorType.Vehicle;
            log.m_factor = ASConfig.EnumMajorFactor.Vehicle;
        } else {
            ss_statuses.ca = [log.snapshot_status];
        }
    }
    log.ss_statuses = ss_statuses;
    if (log.m_factor === ASConfig.EnumMajorFactor.Card && ss_statuses.ca.length > 0) {
        log.snapshot_status = ss_statuses.ca[0];
    } else if (log.m_factor === ASConfig.EnumMajorFactor.Vehicle && ss_statuses.ve.length > 0) {
        log.snapshot_status = ss_statuses.ve[0];
    } else if (log.m_factor === ASConfig.EnumMajorFactor.Face && ss_statuses.fa.length > 0) {
        log.snapshot_status = ss_statuses.fa[0];
    }

    return log;
};

const AccountPictureMap = {
    data: {},
    fetching: false
};
export const OpratorPicture = ({msg_id, operator_id, ...props}) => {
    const { queryAccountList } = useVMSGraphql();
    const [picture, setPicture] = useState(null);

    const isOperator = useMemo(() => ASConfig.IsOperatorMessage(msg_id), [msg_id]);
    
    useEffect(() => {
        if (!isOperator || operator_id <= 0) return;
        
        const fetchData = (id) => {
            if (AccountPictureMap.fetching) {
                setTimeout(() => {
                    fetchData(id);
                }, 100);
            } else {
                AccountPictureMap.fetching = true;
                if (typeof(AccountPictureMap.data[id]) === 'undefined') {
                    queryAccountList((success, data) => {
                        if (success) {
                            data.data.forEach(({id, profile_picture}) => {
                                AccountPictureMap.data[id] = profile_picture;
                            });
                        }
                        AccountPictureMap.data[id] = AccountPictureMap.data[id] || '';
                        setPicture(AccountPictureMap.data[id]);
                        AccountPictureMap.fetching = false;
                    });
                } else {
                    setPicture(AccountPictureMap.data[id]);
                    AccountPictureMap.fetching = false;
                }
            }
        };
        fetchData(operator_id);
    }, [isOperator, operator_id, queryAccountList]);

    if (props.children) {
        return props.children(picture);
    } else {
        return picture;
    }
};
OpratorPicture.defaultProps = {
    msg_id: -1,
    operator_id: 0
};

export const excutePriv = 'x';
export const checkStatus = (gate_status, state) => {
    return (gate_status & state) === state;
};

export const useASConfig = () => {
    const intl = useIntl();
    const { regionList, deviceList, accessRuleList, alertList, specialDayList, accountList, scenarioList, photoViewConfig, accountInfo,
            ajaxASCloud, serviceType
    } = useContext(AppContext);

    const getMessage = (msg_id) => {
        return intl.formatMessage(ASConfig.GetLogMsgFormat(msg_id));
    };

    const getRegion = (rg_id) => {
        return regionList.find(item => item.rg_id === rg_id);
    };

    const getAccessRule = (ar_id) => {
        return accessRuleList.find(item => item.ar_id === ar_id);
    };

    const getAlert = (nt_id) => {
        return alertList.find(item => item.nt_id === nt_id);
    };

    const getSpecialDay = (sd_id) => {
        return specialDayList.find(item => item.sd_id === sd_id)
    };

    const getAccount = (id) => {
        return accountList.find(item => item.id === id);
    };

    const getScenario = (snr_id) => {
        return scenarioList.find(item => item.snr_id === snr_id);
    };

    const getRegionName = (rg_id) => {
        return getRegion(rg_id)?.rg_name;
    };

    const getAccessRuleName = (ar_id) => {
        return getAccessRule(ar_id)?.ar_name;
    };

    const getSpecialDayName = (sd_id) => {
        if (sd_id < ASConfig.specialDayBegin) {
            return moment().weekday(sd_id).format('dddd');
        } else {
            return getSpecialDay(sd_id)?.sd_name;
        }
    };

    const getAccountName = (id) => {
        return getAccount(id)?.name;
    };

    const getScenarioName = (snr_id) => {
        return getScenario(snr_id)?.snr_name;
    };

    const getDeviceTypeName = (device_type) => {
        switch(device_type) {
            case ASConfig.EnumDeviceType.Controller:
                return intl.formatMessage({id: 'controller'});
            case ASConfig.EnumDeviceType.LPR:
                return intl.formatMessage({id: 'lpr'});
            default:
                return '';
        }
    };

    const getDeviceModelName = (device_model) => {
        switch(device_model) {
            case ASConfig.EnumControllerType.ASBridge:
                return 'GV-ASBridge';
            case ASConfig.EnumControllerType.AS1620:
                return 'GV-AS1620';
            case ASConfig.EnumControllerType.CA1330:
                return 'GV-CA1330';
            case ASConfig.EnumControllerType.CA1331:
                return 'GV-CA1331';
            case ASConfig.EnumControllerType.CloudBridgePro:
                return 'GV-Cloud Bridge Pro';
            case ASConfig.EnumControllerType.IA1330:
                return 'GV-IA1330';
            case ASConfig.EnumControllerType.LPR2812:
                return 'GV-LPR2812';
            default:
                return '';
        }
    };

    const getDirName = (gate_dir) => {
        switch (gate_dir) {
            case ASConfig.EnumDirectionType.Entry:
                return intl.formatMessage({id: 'entry'});
            case ASConfig.EnumDirectionType.Exit:
                return intl.formatMessage({id: 'exit'});
            default:
                return;
        }
    };

    const getGateName = (device_type, gate_name, gate_dir) => {
        if (device_type === ASConfig.EnumDeviceType.Controller) {
            var dirName = getDirName(gate_dir);
            if (dirName) {
                if (photoViewConfig.gateFormat === ASConfig.EnumGateFormat.GateDevice) {
                    return `${gate_name} (${dirName})`;
                } else {
                    return `${gate_name} ${photoViewConfig.gateSeparator} ${dirName}`;
                }
            }
        }
        return gate_name;
    };

    const getFullGateName = (params) => {   // {gateFormat, separator, device_name, gate_name, gate_dir, dir_name}
        var dirName = getDirName(params.gate_dir);
        if (params.dir_name) {
            dirName = params.dir_name;
        }

        var gateFormat = params.gateFormat,
            gateSeparator = params.separator;
        if (typeof(gateFormat) === 'undefined') {
            gateFormat = photoViewConfig.gateFormat;
        }
        if (typeof(gateSeparator) === 'undefined') {
            gateSeparator = photoViewConfig.gateSeparator;
        }
    
        var gateName = '';
        switch (gateFormat) {
            case ASConfig.EnumGateFormat.DeviceGate:
                gateName = (params.device_name && params.gate_name) ? `${params.device_name} ${gateSeparator} ${params.gate_name}` : (params.device_name || params.gate_name);
                if (dirName) {
                    gateName = `${gateName} ${gateSeparator} ${dirName}`;
                }
                break;
            case ASConfig.EnumGateFormat.GateDevice:
                if (params.device_name && params.gate_name) {
                    if (dirName) {
                        gateName = `${params.gate_name} (${dirName}) ${gateSeparator} ${params.device_name}`;
                    } else {
                        gateName = `${params.gate_name} ${gateSeparator} ${params.device_name}`;
                    }
                } else if (params.device_name || params.gate_name) {
                    if (dirName) {
                        gateName = `${params.device_name || params.gate_name} ${dirName}`;
                    } else {
                        gateName = params.device_name || params.gate_name;
                    }
                }
                break;
            case ASConfig.EnumGateFormat.Gate:
                if (dirName) {
                    gateName = `${params.gate_name} ${gateSeparator} ${dirName}`;
                } else {
                    gateName = params.gate_name;
                }
                break;
            default:
                break;
        }

        return gateName;
    };

    const getGateTypeName = (gateType) => {
        return intl.formatMessage({id: gateType === ASConfig.EnumGateType.Lane ? 'lane' : 'door'});
    };

    const getFactorName = (m_factor) => {
        switch (m_factor) {
            // case ASConfig.EnumMajorFactor.Gate:
            //     return intl.formatMessage({id: 'gate'});
            case ASConfig.EnumMajorFactor.Card:
                return intl.formatMessage({id: 'card'});
            case ASConfig.EnumMajorFactor.Vehicle:
                return intl.formatMessage({id: 'vehicle'});
            case ASConfig.EnumMajorFactor.Face:
                return intl.formatMessage({id: 'facial_feature'});
            default:
                return '';
        }
    };

    const getFactorTypeName = (b_factor) => {
        switch (b_factor) {
            case ASConfig.EnumFactorType.None:
                return intl.formatMessage({id: 'none'});
            case ASConfig.EnumFactorType.Card:
                return intl.formatMessage({id: 'card'});
            case ASConfig.EnumFactorType.PinCode:
                return intl.formatMessage({id: 'card_pin_code'});
            case ASConfig.EnumFactorType.Vehicle:
                return intl.formatMessage({id: 'vehicle'});
            case ASConfig.EnumFactorType.Face:
                return intl.formatMessage({id: 'facial_feature'});
            case ASConfig.EnumFactorType.Password:
                return intl.formatMessage({id: 'password'});
            default:
                return '';
        }
    };

    const getFactorTypesName = (factors, separator) => {
        var factorNames = [];
        Object.values(ASConfig.EnumFactorType).filter(val => val !== ASConfig.EnumFactorType.None).forEach(val => {
            if ((factors & val) === val) {
                factorNames.push(getFactorTypeName(val));
            }
        });
        return factorNames.join((separator || ',') + ' ') || intl.formatMessage({id: 'none'});
    };

    const getFactorOperatorName = (factorOp) => {
        switch (factorOp) {
            case ASConfig.EnumFactorOperator.Or:
                return intl.formatMessage({id: 'or_factor'});
            case ASConfig.EnumFactorOperator.And:
                return intl.formatMessage({id: 'and_factor'});
            default:
                return '';
        }
    };

    const getReaderTypeName = (reader_type) => {
        switch (reader_type) {
            case ASConfig.EnumReaderType.None:
                return intl.formatMessage({id: 'none'});
            case ASConfig.EnumReaderType.CardReader:
                return intl.formatMessage({id: 'card_reader'});
            case ASConfig.EnumReaderType.LPR:
                return intl.formatMessage({id: 'lpr'});
            case ASConfig.EnumReaderType.GVFRPanel:
                return 'GV-FRPanel';
            case ASConfig.EnumReaderType.GVminiFR:
                return 'GV-miniFR';
            default:
                return '';
        }
    };

    const getCardStatusName = (c_active) => {
        switch(c_active) {
            case ASConfig.EnumCardStatus.Inactive:
                return intl.formatMessage({id: 'card_status_inactive'});
            case ASConfig.EnumCardStatus.Active:
                return intl.formatMessage({id: 'card_status_active'});
            case ASConfig.EnumCardStatus.Active_Lockdown:
                return intl.formatMessage({id: 'card_status_active_lockdown'});
            default:
                return '';
        }
    };

    const getVehicleStatusName = (v_active) => {
        switch(v_active) {
            case ASConfig.EnumVehicleStatus.Inactive:
                return intl.formatMessage({id: 'card_status_inactive'});
            case ASConfig.EnumVehicleStatus.Active:
                return intl.formatMessage({id: 'card_status_active'});
            default:
                return '';
        }
    };

    const getCardCodeName = (value) => {
        switch (value) {
            case ASConfig.EnumCodeFormat.Passcode.code_value:
                return intl.formatMessage({id: 'passcode'});
            case ASConfig.EnumCodeFormat.Wiegand26.code_value:
                return intl.formatMessage({id: 'wiegand26'});
            case ASConfig.EnumCodeFormat.Geo34.code_value:
                return intl.formatMessage({id: 'geo34'});
            case ASConfig.EnumCodeFormat.Geo64.code_value:
                return intl.formatMessage({id: 'geo64'});
            default:
                return '';
        }
    };

    const getAccessAuthModeName = (value) => {
        return value ? intl.formatMessage({id: 'access_rule_card_pin_mode'}) : '';
    }

    const getSpecialDayRuleName = (ruleType) => {
        switch (ruleType) {
            case ASConfig.enumDayRuleType.DateAdd:
                return intl.formatMessage({id: 'specific_date'});
            case ASConfig.enumDayRuleType.Year:
                return intl.formatMessage({id: 'every_year'});
            case ASConfig.enumDayRuleType.MonthWeek:
                return intl.formatMessage({id: 'specific_day'});
            case ASConfig.enumDayRuleType.Month:
                return intl.formatMessage({id: 'every_month'});
            case ASConfig.enumDayRuleType.Week:
                return intl.formatMessage({id: 'every_week'});
            case ASConfig.enumDayRuleType.DateSub:
                return intl.formatMessage({id: 'specific_date_exclude'});
            case '':
                return intl.formatMessage({id: 'preview_all'});
            default:
                return '';
        }
    };

    const getOrdinalWeekName = (week) => {
        if (!Number.isInteger(week)) return '';
        switch (week) {
            case 1:
                return intl.formatMessage({id: 'first'});
            case 2:
                return intl.formatMessage({id: 'second'});
            case 3:
                return intl.formatMessage({id: 'third'});
            case 4:
                return intl.formatMessage({id: 'fourth'});
            default:
                return intl.formatMessage({id: 'last'});
        }
    };

    const getSpecialDayRuleLabel = (ruleType, value) => {
        if (typeof(value) !== 'string') return '';
        if (value.includes('-')) {
            var [start, end] = value.split('-');
            return `${getSpecialDayRuleLabel(ruleType, start)}-${getSpecialDayRuleLabel(ruleType, end)}`;
        }
        switch (ruleType) {
            case ASConfig.enumDayRuleType.DateAdd:
            case ASConfig.enumDayRuleType.DateSub:
                if (value.length !== 6) return;
                return `20${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4, 6)}`;
            case ASConfig.enumDayRuleType.Year:
                if (value.length !== 4) return;
                return `${value.substring(0, 2)}/${value.substring(2, 4)}`;
            case ASConfig.enumDayRuleType.MonthWeek:
                if (value.length !== 4) return;
                return `${moment.months(parseInt(value.substring(0, 2)) - 1)} / ${getOrdinalWeekName(parseInt(value.substring(2, 3)))} / ${moment.weekdays(parseInt(value.substring(3, 4)))}`;
            case ASConfig.enumDayRuleType.Month:
                if (value.length !== 2) return;
                return value;
            case ASConfig.enumDayRuleType.Week:
                if (value.length !== 1) return;
                return moment.weekdays(parseInt(value));
            default:
                return '';
        }
    };

    const getFormatedTime = (value) => {
        if (value) {
            return moment(new Date(value)).format(accountInfo?.time_format || 'MM-DD-YYYY HH:mm:ss');
        }
        return '';
    };

    const getHostType = (device_id) => {
        var device = deviceList.find(item => item.device_id === device_id);
        if (device && ASConfig.EnumControllerHostType[device.device_model]) {
            return ASConfig.EnumControllerHostType[device.device_model];
        }
        return 9;   // Cloud Bridge
    };

    const addAuditLog = (params) => {   // {msg_id, audit_memo, callback}
        var callback = params.callback || function() {};

        ajaxASCloud(Constants.urls.access, {
            api_cmd: ASConfig.EnumASCloudAPIType.ADD_AUDIT_LOG,
            service_type: serviceType,
            msg_id: params.msg_id,
            audit_memo: typeof(params.audit_memo) === 'object' ? JSON.stringify(params.audit_memo) : '{}'
        }, callback);
    };

    const getExcludesAnns = () => {
        var str = window.localStorage.getItem(Constants.storageNames.excludeAnnIds),
            arr = [];
        if (str) {
            try {
                arr = JSON.parse(str);
                if (!Array.isArray(arr)) {
                    arr = [];
                }
            } catch (e) {}
        }
        return arr;
    };

    const showAnnouncement = (ann_id) => {
        if (getExcludesAnns().includes(ann_id)) return;
        const annKey = `ann-${ann_id}`;

        const onClose = () => {
            var dontShow = document.getElementById(`${annKey}-checkbox`).checked;
            message.destroy(annKey);
            if (dontShow) {
                var arr = [...getExcludesAnns(), ann_id];
                window.localStorage.setItem(Constants.storageNames.excludeAnnIds, JSON.stringify(arr));
            }
        };

        message.open({
            key: annKey,
            duration: 0,
            content: (
                <div className='gv-announcement'>
                    <div className='gv-announcement-wrap'>
                        <SVGIcon.Warning className='icon' />
                        <div className='content' dangerouslySetInnerHTML={{__html: intl.formatMessage({id: `ann.${ann_id}`})}}></div>
                        {/* <div className='content'>{intl.formatMessage({id: `ann.${ann_id}`})}</div> */}
                        <span className='close' onClick={onClose}>&#10006;</span>    
                    </div>
                    <Checkbox id={`${annKey}-checkbox`} className='dont-show'>{intl.formatMessage({id: 'dontAnnAgain'})}</Checkbox>
                </div>
            )
        });
    };

    return {
        getMessage, getRegion, getAccessRule, getAlert, getSpecialDay, getAccount, getScenario,
        getRegionName, getAccessRuleName, getSpecialDayName, getAccountName, getScenarioName,
        getDeviceTypeName, getDeviceModelName, getDirName, getGateName, getFullGateName, getGateTypeName,
        getFactorName, getFactorTypeName, getFactorTypesName, getFactorOperatorName, getReaderTypeName, 
        getCardStatusName, getVehicleStatusName, getCardCodeName, getFormatedTime, getHostType,
        getAccessAuthModeName, getSpecialDayRuleName, getOrdinalWeekName, getSpecialDayRuleLabel,
        addAuditLog,
        showAnnouncement
    };
};

export const ASConfigComponent = forwardRef(({...props}, ref) => {
    const ASConfig = useASConfig();
    useImperativeHandle(ref, () => ({
        ...ASConfig
    }));
    return null;
});