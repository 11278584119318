/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */
import LogMessage from './LogMessage.json';

export const emailRegularExpression = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const EnumServiceType = {
    Access: 3,
    Map: 5
};

export const EnumActionType = {
    None:           0,
    Query:          0,
    Add:            1,
    Edit:           1,
    Delete:         2,
    UpdatePin:      101,
    UpdateEnable:   102,
    BatchDelete:    103
};

export const EnumASCloudAPIType = {
    ACCOUNT:                    0,
    ACCOUNT_SETTING:            1,
    USER:                       2,
    OGANIZATION:                3,
    REGION:                     4,
    DEVICE:                     5,
    DEVICE_SEARCH:              6,
    DEVICE_DEACTIVE:            7,
    ACCESSRULE:                 8,
    SPECIALDAY:                 9,
    HOLIDAY:                    10,
    NOTIFICATION:               11,
    UPDATE_DEVICES_GATES_PIN:   12,
    UPDATE_DEVICES_GATES_AUTH:  13,
    ADD_AUDIT_LOG:              14,
    SCENARIO:                   15,
    ACCESSRULE_DEFAULT:         16,
    BATCH_USER:                 17,
    BATCH_USER_PHOTO:           18,
    GET_ACCOUNT_ACCESS_COUNT:   19,
    SHARE_LINK:                 20,
    NEW_FW_INFO:                21,
    BADGE_TEMPLATE:             22,
    COPY_ACCESSRULE:            23,
};

export const EnumDataType = {
    Region:         1 << 0,
    Device:         1 << 1,
    User:           1 << 2,
    Organization:   1 << 3,
    AccessRule:     1 << 4,
    SpecialDay:     1 << 5,
    GateLockdown:   1 << 6,
    GatePin:        1 << 7,
    AccountList:    1 << 8,
    Scenario:       1 << 9,
    BadgeTempldate: 1 << 10,
};

export const EnumErrorCode = {
    ERR_SUCCESS                                    	:  0,
    ERR_INTERNAL_ERROR                             	:  1,
    ERR_PARAMS_INVALID                             	:  2,
    ERR_TOKEN_INVALID                              	:  3,
    ERR_CREATE_DB_ERROR                           	:  4,
    ERR_LOGIN_FAILED                              	:  5,
    ERR_ACCOUNT_NOT_EXIST                          	:  6,
    ERR_ACCOUNT_DB_ACCESS_FAILED                   	:  7,
    ERR_ACCOUNT_S3_ACCESS_FAILED                  	:  8,
    ERR_ACCOUNT_OR_PWD_INVALID                    	:  9,
    ERR_DEVICE_NOT_EXIST                           	: 10,
    ERR_DEVICE_DB_ACCESS_FAILED                    	: 11,
    ERR_GATE_DB_ACCESS_FAILED                     	: 12,
    ERR_USER_DB_ACCESS_FAILED                      	: 13,
    ERR_PAGE_USER_NOT_FOUND                        	: 14,
    ERR_USER_PHOTO_UPLOAD_FAILED                  	: 15,
    ERR_USER_NO_ACCESSOBJECT                        : 16,
    ERR_ACCESSOBJECT_DB_ACCESS_FAILED               : 17,
    ERR_CARD_NO_ALREADY_EXIST                      	: 18,
    ERR_VEHICLE_DB_ACCESS_FAILED                   	: 19,
    ERR_VEHICLE_NO_ALREADY_EXIST                   	: 20,
    ERR_ACCESSOBJECT_ACCESSRULE_UPDATE_FAILED   	: 21,
    ERR_ACCESSRULE_DB_ACCESS_FAILED                	: 22,
    ERR_ACCESSRULE_GATE_DB_ACCESS_FAILED           	: 23,
    ERR_ACCESSRULE_TIME_DB_ACCESS_FAILED           	: 24,
    ERR_ACCESSRULE_TIME_NO_DATA                    	: 25,
    ERR_SPECIALDAY_DB_ACCESS_FAILED                	: 26,
    ERR_SPECIALDAY_DB_CAN_NOT_DELETE_DEFAULT       	: 27,
    ERR_OPEN_GATE_FAILED                           	: 28,
    ERR_HOLIDAY_DB_ACCESS_FAILED                   	: 29,
    ERR_NOTIFICATION_DB_ACCESS_FAILED              	: 30,
    ERR_NOTIFICATION_MSG_DB_ACCESS_FAILED           : 31,
    ERR_NOTIFICATION_GATE_DB_ACCESS_FAILED         	: 32,
    ERR_NOTIFICATION_USER_DB_ACCESS_FAILED        	: 33,
    ERR_ACCOUNT_NO_PERMISSION                       : 34,
    ERR_ACCOUNT_NO_AVAILABLE_PORT                   : 35,
    ERR_ACCOUNT_CAN_NOT_REMOVE_ACTIVE_GATE          : 36,
    ERR_ACCOUNT_ALREADY_INIT                        : 37,
    ERR_ACCOUNT_SUSPEND                             : 38,
    ERR_DEVICE_TYPE_ERROR                           : 39,
    ERR_DEVICE_VERSION_ERROR                        : 40,
    ERR_ORGANIZATION_DB_ACCESS_FAILED               : 41,
    ERR_REGION_DB_ACCESS_FAILED                     : 42,
    ERR_REGION_ADMIN_DB_ACCESS_FAILED               : 43,
    ERR_REGION_NO_ASSIGN                            : 44,
    ERR_USER_MAPPING_NOT_UNIQUE                     : 45,
    ERR_USER_OVERWRITE                              : 46,
    ERR_USER_SKIP                                   : 47,
    ERR_ACCOUNT_NO_PERMISSION_USER                  : 48,
    ERR_ACCESSRULE_NAME_INVALID                     : 49,
    ERR_EXCEED_LIMIT                                : 50,
    ERR_SERVER_BUSY                                 : 51,
    ERR_UNKNOWN_CMD                                 : 52,
    ERR_SERVER_MAINTAIN                             : 53,
    ERR_PASSCODE_ALREADY_EXIST                      : 54,
    ERR_PERSON_PASSCODE_EXCEED_LIMIT                : 55,
    ERR_ACCESSOBJECT_EXCEED_LIMIT                   : 56,
    ERR_SCENARIO_DB_ACCESS_FAILED                   : 57,
    ERR_SCENARIO_GATE_DB_ACCESS_FAILED              : 58,
    ERR_PERSON_FACE_EXCEED_LIMIT                    : 59,
    ERR_USER_NO_PHOTO_FOR_FACE                      : 60,
    ERR_SHAREDLINK_DB_ACCESS_FAILED                 : 61,
    ERR_SHAREDLINK_INVALID                          : 62,
    ERR_SHAREDLINK_PWD_ERROR                        : 63,
    ERR_DEVICE_VERSION_INCOMPATABLE                 : 64,
    ERR_FW_DB_ACCESS_FAILED                         : 65,
    ERR_BADGE_TEMPLATE_DB_ACCESS_FAILED             : 66,
    ERR_DATA_DUPLICATED                             : 67,
};
export const EnumErrorMessage = (() => {
    var msgs = {};
    Object.keys(EnumErrorCode).forEach(key => {
        if (EnumErrorCode[key]) {
            msgs[EnumErrorCode[key]] = 'err_msg.' + EnumErrorCode[key];
        }
    });
    return msgs;
})();

export const EnumAsyncResponseType = {
    IMPORT_USER:        0,
    EXPORT_USER:        1,
    EXPORT_ACCESSLOG:   2,
    EXPORT_LPRLOG:      3,
    EXPORT_SYSTEMLOG:   4
};

export const EnumWebSocketCmd = {
    // Data json format: {"cmd_id": int, "data1": str, "data2": str}

    // To Server
    SYNC_DEVICE:                    1,      // data1: json for device list (["device_mac",...]), sync for all device if data1 is empty, data2: Force Sync("0": False 差異sync, "1": True)
    
    ACCOUNT_LOGOUT:                 2,
    LOCK_DOWN:                      3,      // data1: json for gate list ([{"device_mac":"xxx", "gate_id":0},...]), data2: lock down value (0: false, 1: true)    
    FW_UPDATE:                      4,      // data1: json for device list (["device_mac",...]), update for all device if data1 is empty
    DEVICE_REBOOT:                  5,      // data1: DeviceMac
    
    UNLOCK_GATE:                    101,    // data1: DeviceMac, data2: GateId
    LOCK_GATE:                      102,    // data1: DeviceMac, data2: GateId
    FORCE_UNLOCK_GATE:              103,    // data1: DeviceMac, data2: GateId
    FORCE_LOCK_GATE:                104,    // data1: DeviceMac, data2: GateId
    CLEAR_FORCE_LOCKUNLOCK_GATE:    105,    // data1: DeviceMac, data2: GateId

    CLEAR_BELL:                     151,    // data1: DeviceMac, data2: GateId

    CLEAR_ALL_EVENTS:               201,    // data1: DeviceMac, data2: GateId(empty for all gates)
    CLEAR_FORCED_OPEN:              202,    // data1: DeviceMac, data2: GateId
    CLEAR_FIRE:                     203,    // data1: DeviceMac, data2: GateId
    CLEAR_HELD_OPEN:                204,    // data1: DeviceMac, data2: GateId
    CLEAR_ACCESS_DENIED:            205,    // data1: DeviceMac, data2: GateId
    CLEAR_DEPREDATION:              206,    // data1: DeviceMac, data2: GateId

    OPEN_GATE:                      301,    // data1: DeviceMac, data2: GateId
    GATE_RECOG:                     302,    // data1: DeviceMac, data2: GateId

    // To Browser
    ACCOUNT_DATA_CHANGED:           1001,   // data1: ActionType("1": Add, "2": Delete) 
    ACCOUNT_SUSPEND:                1002,
    DATA_CHANGED:                   1003,   // data1: Data Type (Refer to Data Type Definition)
    GATE_CAM_CHANGED:               1004,   // data1: DeviceMac
    DEVICE_STATUS_CHANGED:          1005,   // data1: DeviceMac, data2: Status(uint1_uint2, uint1: Device status, refer to Device Status Definition, uint2: Device status value, bit 0: off, bit 1: on)
    GATE_STATUS_CHANGED:            1006,   // data1: DeviceMac_GateId, data2: Status
    LOG_UPLOADED:                   1007,   // data1: LogType
    ASYNC_RESPONSE:                 1008,   // data1: Async Response Type, data2: response data
    SERVER_MAINTAIN:                1009,   // Server maintain
    CLIENT_RELOAD:                  1010,   // Browser reload page
    CLIENT_ANNOUNCEMENT:            1011,   // data1: Announcement ids(Json array)


    // broswer defined
    RECONNECT_WEBSOCKET:            10000,
    INVALID_TOKEN:                  10001,
    PHOTO_VIEW_CONFIG_CAHNGED:      10002,
    DEVICE_GATE_NAME_CHANGED:       10003,  // data1: [{device_id, gate_id, name}]
    DEVICE_GATE_DELETED:            10004,  // data1: [{device_id, gate_id}]
    VMS_WEBSOCKET:                  10005,  // From VMS WebSocket
    ABNORMAL_CLOSE_WEBSOCKET:       10006,  // abnormal close websocket
};

export const EnumAccountType = {
    user: 0,
    admin: 1,
    regionAdmin: 2
};

export const EnumThemeType = {
    OSDefault: 0,
    Light: 1,
    Dark: 2
};

export const EnumFactorType = {
    // Gate:       0,
    None:       0,
    Card:       1 << 0,
    PinCode:    1 << 1,
    Vehicle:    1 << 2,
    Face:       1 << 3,
    Password:   1 << 4,
};

export const EnumMajorFactor = {
    // Gate:       -1,
    None:       -1,
    Card:       0,
    Vehicle:    1,
    Face:       2,
};

export const EnumSnapshotStatus = {
    NoImage: 0,
    WithImage: 1,
    HasLarge: 2,
    LackImage: 3,
    NoLicense: 4
};

export const EnumCodeFormat = {
    Passcode: {
        code_value: 25,
        code_A_strlen: 8,
        code_B_strlen: 0,
        code_A_max: 99999999,
        code_B_max: 0,
        is_geo64: 0
    },
    Wiegand26: {
        code_value: 26,
        code_A_strlen: 3,
        code_B_strlen: 5,
        code_A_max: 255,
        code_B_max: 65535,
        is_geo64: 0
    },
    Geo34: {
        code_value: 34,
        code_A_strlen: 5,
        code_B_strlen: 5,
        code_A_max: 65535,
        code_B_max: 65535,
        is_geo64: 0
    },
    Geo64: {
        code_value: 64,
        code_A_strlen: 0,
        code_B_strlen: 19,
        code_A_max: 0,
        code_B_max: 0,
        is_geo64: 1
    }
};

export const EnumPlaybackType = {
    Local: 0,
    Cloud: 1
};

/* Device */
export const EnumGateFormat = {
    DeviceGate: 0,
    GateDevice: 1,
    Gate: 2
};

export const EnumDeviceStatus = {
    NONE:               0,
    SYNCING:            1 << 0,
    SYNC_FAILED:        1 << 1,
    FW_UPDATING:        1 << 2,
    FW_UPDATE_FAILED:   1 << 3,
    CONNECTED:          1 << 4,
    LOGIN_FAILED:       1 << 5,
    TYPE_INCORRECT:     1 << 6,
    VERSION_INVALID:    1 << 7,
    NO_LICENSE:         1 << 8
};

export const EnumVersionStatus = {
    Latest: 0,
    Compatible: 1,
    Incompatible: 2
};

export const EnumGateStatus = {
    NONE:                   0,
    CONNECTED:              1 << 0,
    UNLOCK:                 1 << 1,
    OPEN:                   1 << 2,
    ALARM:                  1 << 3,
    HELD_OPEN:              1 << 4,
    DEPREDATION:            1 << 5,
    DURESS:                 1 << 6,
    FORCED_OPEN:            1 << 7,
    FIRE:                   1 << 8,
    TAILGATING:             1 << 9,
    URGENT_CODE:            1 << 10,
    CAMERA_DISCONNECTED:    1 << 11,
    ENABLE:                 1 << 12,
    FORCE_LOCK:             1 << 13,
    FORCE_UNLOCK:           1 << 14,
    LOCKDOWN:               1 << 15,
    BELL_ACTIVATED:         1 << 16,
    INTERCOM_ANSWERING:     1 << 17,
    RELEASE_MODE:           1 << 18
};
export const arrGateAlarmStatus = [EnumGateStatus.ALARM, EnumGateStatus.HELD_OPEN, EnumGateStatus.DEPREDATION, EnumGateStatus.DURESS, EnumGateStatus.FORCED_OPEN, 
                                    EnumGateStatus.FIRE, EnumGateStatus.TAILGATING, EnumGateStatus.URGENT_CODE, EnumGateStatus.CAMERA_DISCONNECTED];

export const EnumDirectionType = {
    Entry: 0,
    Exit: 1
};

export const EnumDeviceType = {
    Controller: 1,
    LPR: 2
};
export const EnumControllerType = {
    ASBridge:       0,
    AS1620:         1,
    CA1330:         2,
    CA1331:         3,
    CloudBridgePro: 4,
    IA1330:         5,
    LPR2812:        1001
};
export const EnumControllerHostType = {
    [EnumControllerType.ASBridge]:          12,
    [EnumControllerType.AS1620]:            13,
    [EnumControllerType.CA1330]:            14,
    [EnumControllerType.CA1331]:            14,
    [EnumControllerType.CloudBridgePro]:    9,
    [EnumControllerType.IA1330]:            16,
    [EnumControllerType.LPR2812]:           9,
};
export const EnumLPRType = {
    AIBox: 0
};

export const EnumGateType = {
    Door:   0,
    Lane:   1
};

export const EnumReaderType = {
    None:               0,
    CardReader:         1,
    LPR:                3,
    GVFRPanel:          4,
    GVminiFR:           5
};

export const EnumBitReaderType = {
    None:               0,
    CardReader:         1 << 0,
    LPR:                1 << 1,
    GVFRPanel:          1 << 2,
    GVminiFR:           1 << 3
};

export const EnumFactorOperator = {
    Or:                 0,
    And:                1
};

export const EnumSuportedAuthMode = {
    Authentication: 0,
    Release:        1 << 0,
    ReleaseByCard:  1 << 1,
}

export const defaultDeviceSupportConfig = {
    onlineUpdate: 0,
    maxDoorNum: 4,
    readerTypes: EnumBitReaderType.CardReader,
    dst: 1,
    lockResetTime: 1,
    heldOpenTime: 1,
    authScheduleModes: EnumSuportedAuthMode.Authentication + EnumSuportedAuthMode.Release + EnumSuportedAuthMode.ReleaseByCard
};

export const EnumDeviceDefaultSupports = {
    [EnumControllerType.ASBridge]: {
        ...defaultDeviceSupportConfig,
        onlineUpdate: 1
    },
    [EnumControllerType.AS1620]: {
        ...defaultDeviceSupportConfig,
        onlineUpdate: 1,
        maxDoorNum: 1
    },
    [EnumControllerType.CA1330]: {
        ...defaultDeviceSupportConfig,
        maxDoorNum: 1
    },
    [EnumControllerType.CA1331]: {
        ...defaultDeviceSupportConfig,
        maxDoorNum: 1
    },
    [EnumControllerType.IA1330]: {
        ...defaultDeviceSupportConfig,
        maxDoorNum: 1
    },
    [EnumControllerType.LPR2812]: {
        ...defaultDeviceSupportConfig,
        maxDoorNum: 1,
        readerTypes: EnumBitReaderType.LPR,
        dst: 0,
        lockResetTime: 0,
        heldOpenTime: 0,
        authScheduleModes: EnumSuportedAuthMode.Authentication
    }
};

export const EnumLogDSTType = {
    None: 0,
    InDST: 1,
    InDSTOverlay: 2
};

export const specialDayBegin = 7;
export const timezoneScaleCount = 288;

export const EnumCardStatus = {
    Inactive: 0,
    Active: 1,
    Active_Lockdown: 2
};

export const EnumVehicleStatus = {
    Inactive: 0,
    Active: 1
};

export const enumDayRuleType = {
    DateAdd: 'ymda',
    Year: 'md',
    MonthWeek: 'mwd',
    Month: 'd',
    Week: 'w',
    DateSub: 'ymds'
};

export const EnumShareLinkState = {
    None: 0,
    Manager: 1,
    Client: 2
};

/* Log */
export const EnumLogType = {
    Access: 0,
    Event: 1,
    // LPR: 2,
    System: 3
};

export const EnumMessageType = {
    Access: 0,
    Event: 1,
    // LPR: 2,
    System: 3,
};

export const EnumMobileAlertMessageType = {    // for notification
    Access: -100,
    Event: -101,
    // LPR: -102,
    System: -103,
};

export const EnumRelevantLogType = {    // for notification
    User: 0,
    Door: 1,
    Device: 2,
    Other: 3
};

export const MessageTypeString = (msgType) => {
    return `event_type.${msgType}`;
};

export const EnumLogMessage = (() => {
    let msgs = [];
    LogMessage.messages.forEach(item => {
        if (!process.env.REACT_APP_ENABLE_VEHICLE && [1003, 1002, 1004, 1005].includes(item.id)) {
            item.hd = 1;
        }
        if (!process.env.REACT_APP_ENABLE_VEHICLE && !process.env.REACT_APP_ENABLE_FACE && [12, 13].includes(item.id)) {
            item.hd = 1;
        }
        msgs.push({
            msgType: item.type,
            value: item.id,
            warning: !!item.wn,
            directional: !!item.dir,
            snapshot: !!item.ss,
            relevant: item.rl,
            hidden: !!item.hd,
            notification: !!item.nt,
            operator: !!item.op,
            intlFormat: {id: `event_ids.${item.id}`, defaultMessage: item.cm}
        });
    });
    return msgs;
})();


export const EnumAccessLogMsg = EnumLogMessage.filter(item => item.msgType === EnumMessageType.Access && !item.hidden);
export const EnumEventLogMsg = EnumLogMessage.filter(item => item.msgType === EnumMessageType.Event && !item.hidden);
// export const EnumLPRLogMsg = EnumLogMessage.filter(item => item.msgType === EnumMessageType.LPR && !item.hidden);
export const EnumSystemLogMsg = EnumLogMessage.filter(item => item.msgType === EnumMessageType.System && !item.hidden);

export function GetLogMsgFormat(id) {
    return EnumLogMessage.find(item => item.value === id)?.intlFormat || {
        id: '_',
        defaultMessage: ' '
    };
}
export function IsEventLog(msg_id) {
    return EnumLogMessage.find(item => item.value === msg_id)?.msgType === EnumMessageType.Event;
}
// export function IsLPRLog(msg_id) {
//     return EnumLogMessage.find(item => item.value === msg_id)?.msgType === EnumMessageType.LPR;
// }
export function IsSystemLog(msg_id) {
    return EnumLogMessage.find(item => item.value === msg_id)?.msgType === EnumMessageType.System;
}
export function IsWarningMessage(msg_id) {
    return !!EnumLogMessage.find(item => item.value === msg_id)?.warning;
}
export function IsNeedSnapshotMessage(msg_id) {
    return !!EnumLogMessage.find(item => item.value === msg_id)?.snapshot;
}
export function IsOperatorMessage(msg_id) {
    return !!EnumLogMessage.find(item => item.value === msg_id)?.operator;
}

export const EnumAuditLogMessage = {
    ClearBell:              40107,
    InterComAnswering:      40108,

    ImportUserStarted:      40807,
    ImportUserCompleted:    40808,
    ImportUserNotCompleted: 40809,
    ExportAccessLogs:       40825,
    ExportLPRLogs:          40826,
    ExportSystemLogs:       40827,
    ExportUsers:            40828,

    BatchDeleteShareLink:   40836,
    SendShareLink:          40837,

    AddMap:                 41901,
    EditMap:                41902,
    DeleteMap:              41903,
    EditPin:                41904,
};