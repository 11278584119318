import React, { useState, useEffect, useRef, useMemo, forwardRef, useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { format } from 'react-string-format';
import { Modal, Empty, Tooltip, Spin, Input, Popover, Dropdown, Form } from 'antd';
import { Button, Card } from 'react-bootstrap';
import { BsExclamationTriangleFill, BsFillQuestionCircleFill, BsCheckLg, BsXCircleFill, BsFillInfoCircleFill } from "react-icons/bs";
import { Textfit } from 'react-textfit';
import { EnumLogType, EnumMajorFactor, EnumVersionStatus } from '../ASUtils/ASConfig';
import { AppContext } from '../../Utils.js';
import { SegmentedEx } from '../ASUtils/ASUtils.js';
import { EnumLogViewMode } from '../Common/LogView';
import SVGIcon from '../../icons.js';
import './Common.css';
import EmptySnapshot from '../../images/snapshot_empty.svg';
import EmptyUserPhoto  from '../../images/user_noimg.svg';

export function ShowAlertMessage({type, msg, show, doubleComfirm, getContainer, onClose, btn_text_close, btn_text_ok, ...props}) {
    const msgIcon = useMemo(() => {
        switch (type) {
            case 'warning':
                return <div className='commom-msg-icon warning'><BsExclamationTriangleFill /></div>;
            case 'success':
                return <div className='commom-msg-icon success'><BsCheckLg /></div>;
            case 'error':
                return <div className='commom-msg-icon error'><BsXCircleFill /></div>;
            case 'question':
                return <div className='commom-msg-icon question'><BsFillQuestionCircleFill /></div>;
            default:
                return null;
        }
    }, [type]);

    const [confirm, setConfirm] = useState('');

    return (
        <Modal width={500} {...props} centered open={show} onCancel={() => onClose(false)} onOk={() => onClose(true)} getContainer={getContainer}
            cancelText={type === 'question' ? <FormattedMessage id="cancel" defaultMessage="Cancel" /> : btn_text_close}
            okText={btn_text_ok}
            okButtonProps={{hidden: type !== 'question', disabled: doubleComfirm && type === 'question' && confirm !== 'DELETE'}}
        >
            <div className='common-alert-body scroll-box'>
                { msgIcon }
                <span>{msg}</span>
            </div>
            {
                doubleComfirm && type === 'question' &&
                <Form colon={false} layout='vertical' className='common-alert-form' labelWrap>
                    <Form.Item name='confirm_input' label={<FormattedMessage id='double_confirm_delete' />} value={confirm} onChange={e => setConfirm(e.target.value)}>
                        <Input placeholder='DELETE' />
                    </Form.Item>
                </Form>
            }
        </Modal>
    );
}
ShowAlertMessage.defaultProps = {
    show: false,
    title: '',
    msg: '',
    type: 'warning',   // warning, success, error, question
    doubleComfirm: false,
    getContainer: null,
    btn_text_ok: <FormattedMessage id="ok" defaultMessage="OK" />,    // Only for type === 'question'
    btn_text_close: <FormattedMessage id="close" defaultMessage="Close" />,
    onClose: function() {},  // return true/false
};

const ReleaseNoteCacheMap = {}; // release note cache {device_model: {version: {lang}}}
const getReleaseNote = async (device_model, version, lang, link) => {
    if (ReleaseNoteCacheMap[device_model]?.[version]) {
        if (ReleaseNoteCacheMap[device_model][version][lang]) return ReleaseNoteCacheMap[device_model][version][lang];
        if (ReleaseNoteCacheMap[device_model][version][lang] === null) return ReleaseNoteCacheMap[device_model][version]['en']; // already fetch once
    }
    if (typeof(ReleaseNoteCacheMap[device_model]?.[version]?.[lang]) !== 'undefined') return ReleaseNoteCacheMap[device_model][version][lang];

    const fetchData = async (_lang) => {
        if (!link) return null;
        const resp = await fetch(`${link}_${_lang}.html`);
        if (resp.ok) {
            const html = await resp.text();
            return html;
        } else {
            return null;
        }
    };

    var html = await fetchData(lang);
    if (!ReleaseNoteCacheMap[device_model]) ReleaseNoteCacheMap[device_model] = {};
    if (!ReleaseNoteCacheMap[device_model][version]) ReleaseNoteCacheMap[device_model][version] = {};
    ReleaseNoteCacheMap[device_model][version][lang] = html;

    if (!html && lang !== 'en') {
        html = await fetchData('en');
        ReleaseNoteCacheMap[device_model][version]['en'] = html; 
    }
    return html;
};

export function  ComfirmUpdateDevice({device, msg, show, onlyInfo, getContainer, onClose, ...props}) {
    const intl = useIntl();
    const { accountInfo, newFwList, getNewFwList} = useContext(AppContext);
    const [releaseNote, setReleaseNote] = useState(null);

    useEffect(() => {
        if (show) {getNewFwList()}
    }, [getNewFwList, show]);

    useEffect(() => {
        setReleaseNote(null);
        if (!device || !newFwList.length) return;
        const fetchData = async () => {
            var fwList = newFwList.filter(fw => fw.fw_model === device.device_model && fw.fw_version > device.device_version);
            if (fwList.length > 0) {
                var fw = fwList[device.support_config.onlineUpdate ? fwList.length - 1 : 0];
                fw.html = await getReleaseNote(fw.fw_model, fw.fw_version, accountInfo.lang, fw.fw_link);
                setReleaseNote(fw);
            }
        };
        fetchData();
    }, [device, newFwList, accountInfo.lang]);

    const msgIcon = useMemo(() => {
        if (device?.support_config?.onlineUpdate) {
            return <div className='commom-msg-icon question'><BsFillQuestionCircleFill /></div>;
        } else {
            return <div className='commom-msg-icon info'><BsFillInfoCircleFill /></div>;
        }
    }, [device?.support_config?.onlineUpdate]);

    const handleClose = () => {
        onClose(device?.support_config?.onlineUpdate);
    };

    return (
        <Modal className='common-update-device-modal' width={680} {...props} centered open={show} onCancel={() => onClose(false)} onOk={handleClose} getContainer={getContainer}
            title={`${intl.formatMessage({id: device?.version_status === EnumVersionStatus.Incompatible ? 'firmware_must_updated' : 'update_firmware_available'})} [${device?.device_name}]`}
            cancelText={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
            okText={<FormattedMessage id="ok" defaultMessage="OK" />}
            cancelButtonProps={{hidden: !device?.support_config?.onlineUpdate || onlyInfo}}
            okButtonProps={{hidden: onlyInfo}}
        >
            <Card className='common-release-note-card'>
                <Card.Header>
                    <span>
                        <FormattedMessage id='release_note' />
                        {/* {releaseNote ? `(v${releaseNote.fw_version})` : ''} */}
                    </span>
                    {
                        !device?.support_config?.onlineUpdate && releaseNote?.fw_link && 
                        <a href={`${releaseNote.fw_link}.bin`} style={{marginLeft: '0.5rem'}}>
                            <FormattedMessage id='download' />
                        </a>
                    }
                </Card.Header>
                <Card.Body className='common-update-body scroll-box'>
                {
                    !!releaseNote?.html ?
                    <div dangerouslySetInnerHTML={{__html: releaseNote?.html}} />
                    :
                    <NoDataMask />
                }
                </Card.Body>
            </Card>
            {
                (!onlyInfo || !device?.support_config?.onlineUpdate) &&
                <div className='common-alert-body'>
                    { msgIcon }
                    <span>
                        {device?.support_config?.onlineUpdate ? msg : <FormattedMessage id='not_support_online_update_description' />}
                    </span>
                </div>
            }
        </Modal>
    );
};
ComfirmUpdateDevice.defaultProps = {
    device: null,
    show: false,
    msg: '',
    onlyInfo: false,
    getContainer: null,
    onClose: function() {},  // return true/false
};

export function LoadingMask({show, text, transparentBackground}) {
    return (
        <div className={'common-mask' + (show? '' : ' hide') + (transparentBackground? ' transparent' : '')}>
            <Spin size='large'/>
            {
                !!text &&
                <span>{text}</span>
            }
        </div>
    );
}
LoadingMask.defaultProps = {
    show: false,
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    transparentBackground: false
};

export function NoDataMask(props) {
    return (
        <Empty className='common-no-data' description={<FormattedMessage id='no_data' defaultMessage='No data' />} image={<SVGIcon.NoData />}>
        { props.children }
        </Empty>
    );
}

export function ProgressBarMask(props) {
    return (
        <div className={'common-mask' + (props.show? '' : ' hide')}>
            <Spin size='large' tip={props.text}>&nbsp;</Spin>
            <Button onClick = {props.onClose}>{props.btn_text}</Button>
        </div>
    );
}
ProgressBarMask.defaultProps = {
    show: false,
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    btn_text: <FormattedMessage id="cancel" defaultMessage="Cancel" />,
    onClose: function() {}
}

// window.open in Edge will open new empty tab
export function DownloadFile(fileUrl, defaultFileName) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = defaultFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const ASImageCacheMap = {}; // by log {log_type}-{log_id}-{m_factor}-{index}, by user-{user_id}, by operator-{user_id}
const ASImageStatus = {
    Ignore: -1,
    CacheImage: 0,  // Use cache image
    ParmasImage: 1, // Use parameter image
    LoadError: 2,   // Image Load Error
    ForceSrc: 3     // force Use Src Image
};
export function ASImage({log_type, log_id, u_user_id, m_factor, index, src, force, error_image, noErrorImage, onLoad, onError, ...props}) {
    const [imgSrc, setImgSrc] = useState(null);
    const [status, setStatus] = useState(ASImageStatus.CacheImage);

    const imgKey = useMemo(() => {
        var key;
        switch (log_type) {
            case 'user':
            case 'operator':
                if (u_user_id > -1) {
                    if (u_user_id === 0) {
                        key = `${log_type}_all`;    // all user
                    } else {
                        key = `${log_type}_${u_user_id}`;
                    }
                }
                break;
            case EnumLogType.Access:
            // case EnumLogType.LPR:
                if (log_id > -1) {
                    key = `${log_type}_${log_id}_${m_factor}_${index}`;
                }
                break;
            default:
                break;
        }
        return key;
    }, [log_type, log_id, u_user_id, m_factor, index]);

    useEffect(() => {
        var cacheImg;
        if (force && src) {
            setStatus(ASImageStatus.ForceSrc);
            setImgSrc(src);
            return;
        } else if (imgKey) {
            if (imgKey === 'user_all' || imgKey === 'operator_all') {
                cacheImg = EmptyUserPhoto;
                setStatus(ASImageStatus.LoadError);
            } else {
                cacheImg = ASImageCacheMap[imgKey];
            }
        } else {
            if (src) {
                setStatus(ASImageStatus.Ignore);
                setImgSrc(src);
            } else {
                setStatus(ASImageStatus.LoadError);
                setImgSrc(error_image || EmptySnapshot);
            }
            return;
        }

        if (cacheImg) {
            setImgSrc(cacheImg);
        } else if (src) {
            setStatus(ASImageStatus.ParmasImage);
            setImgSrc(src);
        } else {
            setStatus(ASImageStatus.LoadError);
            if (imgKey.includes('user') || imgKey.includes('operator')) {
                setImgSrc(error_image || EmptyUserPhoto);
            } else {
                setImgSrc(error_image || EmptySnapshot);
            }
        }
    }, [imgKey, src, force, error_image]);

    const handleLoad = e => {
        if (imgKey && (status === ASImageStatus.ParmasImage || status === ASImageStatus.ForceSrc)) {
            ASImageCacheMap[imgKey] = e.target.src;
        }

        if (status === ASImageStatus.LoadError) {
            onError(e);
        } else {
            onLoad(e);
        }
    };

    const handleError = e => {
        if (status === ASImageStatus.CacheImage) {
            if (imgKey) {
                delete ASImageCacheMap[imgKey];
            }

            if (src) {
                setStatus(ASImageStatus.ParmasImage);
                setImgSrc(src);
            } else {
                setStatus(ASImageStatus.Ignore);
                if (log_type === 'user' || log_type === 'operator') {
                    setImgSrc(error_image || EmptyUserPhoto);
                } else {
                    setImgSrc(error_image || EmptySnapshot);
                }
            }
        } else if (status === ASImageStatus.ForceSrc) {
            var cacheImg = imgKey ? ASImageCacheMap[imgKey] : null;
            if (cacheImg) {
                setStatus(ASImageStatus.CacheImage);
                setImgSrc(cacheImg);
            } else {
                if (noErrorImage) {
                    onError(e);
                } else {
                    setStatus(ASImageStatus.LoadError);
                    if (log_type === 'user' || log_type === 'operator') {
                        setImgSrc(error_image || EmptyUserPhoto);
                    } else if (log_id > -1) {
                        setImgSrc(error_image || EmptySnapshot);
                    } else {
                        onError(e);
                    }
                }
            }
        } else {
            setStatus(ASImageStatus.LoadError);
            if (noErrorImage) {
                onError(e);
            } else {
                if (log_type === 'user' || log_type === 'operator') {
                    setImgSrc(error_image || EmptyUserPhoto);
                } else if (log_id > -1) {
                    setImgSrc(error_image || EmptySnapshot);
                } else {
                    onError(e);
                }
            }
        }
    };

    return (
        <Card.Img {...props} src={imgSrc} onLoad={handleLoad} onError={handleError} />
    );
}
ASImage.defaultProps = {
    log_type: -1,
    log_id: -1,
    u_user_id: -1,
    m_factor: EnumMajorFactor.Card,
    index: 0,
    src: '',
    alt: '',
    force: false,
    onLoad: function() {},
    onError: function(e) {},
    error_image: null,
    noErrorImage: false
};
export function ClearASImageUserCache(u_user_id) {
    delete ASImageCacheMap[`user_${u_user_id}`];
}

const baseScale = 1000;
const PlateNoContext = React.createContext();
export const PlateNoPhotoText = ({v_rect, plateWidth, hidden, onRender, children, ...props}) => {
    const [img, setImg] = useState(null);

    const rect = useMemo(() => {
        if (hidden) return null;
        var x1, x2, y1, y2;
        try {
            [x1, y1, x2, y2] = JSON.parse(v_rect);
        } catch (e) {}
        x1 = x1 || 0;
        x2 = x2 || 0;
        y1 = y1 || 0;
        y2 = y2 || 0;
        if ((x1 === 0 && x2 === 0) || (y1 === 0 && y2 === 2)) return null;
        return {x1, x2, y1, y2};
    }, [v_rect, hidden]);

    useEffect(() => {
        if (v_rect && !rect) {
            onRender(false);
        }
    }, [v_rect, rect, onRender]);

    const { scale, plateHeight } = useMemo(() => {
        var scale = 1,
            plateHeight = 0;
        try {
            if (!img || !rect) throw new Error();

            scale = plateWidth / img.naturalWidth * baseScale / (rect.x2 - rect.x1) ;
            plateHeight = img.naturalHeight * scale * (rect.y2 - rect.y1) / baseScale;
        } catch {}
        return {scale, plateHeight};
    }, [img, rect, plateWidth]);

    return (
        <PlateNoContext.Provider value={{img, setImg, rect, scale, hidden, plateWidth, plateHeight, onRender}}>
        {children}
        </PlateNoContext.Provider>
    );
};
PlateNoPhotoText.defaultProps = {
    v_rect: '',
    plateWidth: 120,
    hidden: false,
    onRender: (success) => {}
};
const PlatePhoto = ({imgProps, imgSrc, ...props}) => {
    const {img, setImg, rect, scale, hidden, plateWidth, plateHeight, onRender} = useContext(PlateNoContext);

    const { imgWidth, imgHeight } = useMemo(() => {
        var imgWidth = 0, imgHeight = 0;
        if (img) {
            imgWidth = img.naturalWidth * scale;
            imgHeight = img.naturalHeight * scale;
        }
        return {imgWidth, imgHeight};
    }, [img, scale]);

    const marginLeft = useMemo(() => rect ? rect.x1 / baseScale * imgWidth * - 1 : 0, [rect, imgWidth]);
    const marginTop = useMemo(() => rect ? rect.y1 / baseScale * imgHeight * - 1 : 0, [rect, imgHeight]);

    return (
        !!imgSrc && rect && !hidden &&
        <div className='common-plate-no-photo' style={{width: `${plateWidth}px`, height: `${plateHeight}px`, display: plateHeight > 0 ? 'block' : ''}}>
            <ASImage {...imgProps} src={imgSrc} onLoad={e => {setImg(e.target); setTimeout(() => {onRender(true);}, 300);}} onError={() => onRender(false)}
                style={{width: `${imgWidth}px`, height: `${imgHeight}px`, marginLeft: `${marginLeft}px`, marginTop: `${marginTop}px`}}
            />
        </div>
    );
};
PlatePhoto.defaultProps = {
    imgProps: null,
    imgSrc: ''
};
const PlateText = ({v_no}) => {
    const {plateWidth, plateHeight} = useContext(PlateNoContext);
    const [ready, setReady] = useState(false);

    return (
        !!plateHeight && v_no &&
        <Textfit className={`common-plate-no-text ${ready ? 'ready' : ''}`} mode='single' min={12} max={70} forceSingleModeWidth={false}
            style={{width: `${plateWidth}px`, height: `${plateHeight}px`}} onReady={() => setReady(true)}
        >
            {v_no}
        </Textfit>
    );
};
PlateText.defaultProps = {
    v_no: '',
};
PlateNoPhotoText.Photo = PlatePhoto;
PlateNoPhotoText.Text = PlateText;


export class TimeRangePicker extends React.Component {
    itemHeight = 28;
    scrollTimer = null;
    constructor(props) {
        super(props);

        this.state = {
            startHour: 0,
            startMin: 0,
            endHour: 0,
            endMin: 0
        };

        this.startHourPanel = React.createRef();
        this.startMinPanel = React.createRef();
        this.endHourPanel = React.createRef();
        this.endMinPanel = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleWheel = this.handleWheel.bind(this);
    }

    componentDidMount() {
        this.startHourPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.startMinPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.endHourPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.endMinPanel.current.addEventListener('wheel', this.handleWheel, { passive: false});
        this.setVlaue();
    }

    componentWillUnmount() {
        this.startHourPanel.current.removeEventListener('wheel', this.handleWheel);
        this.startMinPanel.current.removeEventListener('wheel', this.handleWheel);
        this.endHourPanel.current.removeEventListener('wheel', this.handleWheel);
        this.endMinPanel.current.removeEventListener('wheel', this.handleWheel);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            this.setVlaue();
        }
        if (this.state.startHour !== prevState.startHour || this.state.startMin !== prevState.startMin ||
            this.state.endHour !== prevState.endHour || this.state.endMin !== prevState.endMin) {
            clearTimeout(this.scrollTimer);
            this.scrollTimer = setTimeout(this.scroll, 50);
        }
    }

    setVlaue = () => {
        var splitTime = this.props.value.split(' - '),
            startTime = splitTime[0],
            endTime = splitTime[1];
        
        var splitStart = startTime.split(':'),
            splitEnd = endTime.split(':');
        this.setState({
            startHour: parseInt(splitStart[0]),
            startMin: parseInt(splitStart[1]),
            endHour: parseInt(splitEnd[0]),
            endMin: parseInt(splitEnd[1])
        });
    };

    scroll = () => {
        if (this.startHourPanel.current) {
            this.startHourPanel.current.scrollTo({top: this.state.startHour * this.itemHeight, behavior: 'smooth'});
        }
        if (this.startMinPanel.current) {
            this.startMinPanel.current.scrollTo({top: this.state.startMin / 5 * this.itemHeight, behavior: 'smooth'});
        }
        if (this.endHourPanel.current) {
            this.endHourPanel.current.scrollTo({top: this.state.endHour * this.itemHeight, behavior: 'smooth'});
        }
        if (this.endMinPanel.current) {
            this.endMinPanel.current.scrollTo({top: this.state.endMin / 5 * this.itemHeight, behavior: 'smooth'});
        }
        this.scrollTimer = null;
    };

    handleChange = (name, value) => {
        var label = '',
            adjustMin;
        const strFormat = '{0}:{1} - {2}:{3}';
        
        switch(name) {
            case 'sh':
                adjustMin = this.state.startMin;
                if (value === this.state.endHour) {
                    if (this.props.ignoreValid && this.state.startMin === this.state.endMin) {
                        adjustMin = this.state.startMin > 0 ? this.state.startMin - 5 : 5;
                    } else if (!this.props.ignoreValid && this.state.startMin >= this.state.endMin) {
                        adjustMin = this.state.endMin - 5;
                    }
                }
                label = format(strFormat, value.toString().padStart(2, '0'), adjustMin.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), this.state.endMin.toString().padStart(2, '0'));
                break;
            case 'sm':
                label = format(strFormat, this.state.startHour.toString().padStart(2, '0'), value.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), this.state.endMin.toString().padStart(2, '0'));
                break;
            case 'eh':
                adjustMin = this.state.endMin;
                if (value === this.state.startHour) {
                    if (this.props.ignoreValid && this.state.startMin === this.state.endMin) {
                        adjustMin = this.state.endMin < 55 ? this.state.endMin + 5 : 0;
                    } else if (!this.props.ignoreValid && this.state.endMin <= this.state.startMin) {
                        adjustMin = this.state.startMin + 5;
                    }
                }
                adjustMin = value === 24 ? 0 : adjustMin;
                label = format(strFormat,  this.state.startHour.toString().padStart(2, '0'), this.state.startMin.toString().padStart(2, '0'), value.toString().padStart(2, '0'), adjustMin.toString().padStart(2, '0'));
                break;
            case 'em':
                label = format(strFormat, this.state.startHour.toString().padStart(2, '0'), this.state.startMin.toString().padStart(2, '0'), this.state.endHour.toString().padStart(2, '0'), value.toString().padStart(2, '0'));
                break;  
            default:
                return;
        }
        this.props.onChange(label);
    };

    handleClick = e => {
        var name = e.target.getAttribute('name'),
            value = parseInt(e.target.getAttribute('eventkey'));
        
        this.handleChange(name, value);
    };

    handleWheel = e => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = false;  
        var name = e.target.getAttribute('name'),
            step = Math.round(e.deltaY * 0.01),
            value;

        switch(name) {
            case 'sh':
                value = this.state.startHour + step;
                if (value < 0 || value > 23) return;
                if (!this.props.ignoreValid && (value > this.state.endHour || (value === this.state.endHour && this.state.endMin === 0))) {
                    return;
                }
                if (this.startHourPanel.current) {
                    this.startHourPanel.current.scrollTo({top: value * this.itemHeight});
                }
                break;
            case 'sm':
                value = this.state.startMin + step * 5;
                if (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.endMin) {
                    value += step * 5;
                }
                if (value < 0 || value > 55) return;
                if (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value >= this.state.endMin) {
                    return;
                }
                if (this.startMinPanel.current) {
                    this.startMinPanel.current.scrollTo({top: value / 5 * this.itemHeight});
                }
                break;
            case 'eh':
                value = this.state.endHour + step;
                if (value < 0 || value > 24) return;
                if (!this.props.ignoreValid && (value < this.state.startHour || (value === this.state.startHour && this.state.startMin === 55))) {
                    return;
                }
                if (this.endHourPanel.current) {
                    this.endHourPanel.current.scrollTo({top: value * this.itemHeight});
                }
                break;
            case 'em':
                value = this.state.endMin + step * 5;
                if (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.startMin) {
                    value += step * 5;
                }
                if (value < 0 || value > 55) return;
                if ((!this.props.ignoreValid && this.state.startHour === this.state.endHour && value <= this.state.startMin) || (this.state.endHour === 24 && value !== 0)) {
                    return;
                }
                if (this.endMinPanel.current) {
                    this.endMinPanel.current.scrollTo({top: value / 5 * this.itemHeight});
                }
                break;
            default:
                return;
        }
        this.handleChange(name, value);
    };

    render() {
        return (
            <div className='time-range-picker noselect'>
                <div ref={this.startHourPanel} name='sh' className='time-range-picker-column'>
                    {
                        (Array.from(Array(24).keys())).map((item, idx) => {
                            var key = idx.toString().padStart(2, '0');
                            var disabled = !this.props.ignoreValid && (idx > this.state.endHour || (idx === this.state.endHour && this.state.endMin === 0));
                            return <div key={idx} eventkey={key} name='sh' onClick={this.handleClick} className={(idx === this.state.startHour? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>:</span>
                <div ref={this.startMinPanel} name='sm' className='time-range-picker-column'>
                    {
                        (Array.from(Array(12).keys())).map((item, idx) => {
                            var value = idx * 5;
                            var key = value.toString().padStart(2, '0');
                            var disabled = (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.endMin) ||
                                            (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value >= this.state.endMin);
                            return <div key={key} eventkey={key} name='sm' onClick={this.handleClick} className={(value === this.state.startMin? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>-</span>
                <div ref={this.endHourPanel} name='eh' className='time-range-picker-column'>
                    {
                        (Array.from(Array(25).keys())).map((item, idx) => {
                            var key = idx.toString().padStart(2, '0');
                            var disabled = !this.props.ignoreValid && (idx < this.state.startHour || (idx === this.state.startHour && this.state.startMin === 55));
                            return <div key={idx} eventkey={key} name='eh' onClick={this.handleClick} className={(idx === this.state.endHour? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
                <span>:</span>
                <div ref={this.endMinPanel} name='em' className='time-range-picker-column'>
                    {
                        (Array.from(Array(12).keys())).map((item, idx) => {
                            var value = idx * 5;
                            var key = value.toString().padStart(2, '0');
                            var disabled = (this.props.ignoreValid && this.state.startHour === this.state.endHour && value === this.state.startMin) ||
                                            (!this.props.ignoreValid && this.state.startHour === this.state.endHour && value <= this.state.startMin) || 
                                            (this.state.endHour === 24 && value !== 0);
                            return <div key={key} eventkey={key} name='em' onClick={this.handleClick} className={(value === this.state.endMin? 'selected' : '') + (disabled? ' disabled' : '')}>
                                        {key}
                                    </div>;
                        })
                    }
                </div>
            </div>
        );
    }
}
TimeRangePicker.defaultProps = {
    ignoreValid: false,
    value: '00:00 - 00:00',
    onChange: function() {}
};


const FieldToggle = React.forwardRef(({id, disabled, ignoreValid, onClick, onChange, isNextDay, ...props}, ref) => {
    const regex = new RegExp('^([01][0-9]|[2][0-3]):([0-5][05]) - ([01][0-9]|[2][0-4]):([0-5][05])$');
    const intl = useIntl();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleClick = e => {
        e.preventDefault();
        if (!disabled) {
            onClick(e);
        }
    };

    const handleChange = (e) => {
        var val = e.target.value;
        setValue(val);

        if (regex.test(val)) {
            var [strStart, strEnd] = val.split(' - '),
                splitStart = strStart.split(':'),
                splitEnd = strEnd.split(':');;

            var startTime = parseInt(splitStart[0]) * 60 + parseInt(splitStart[1]),
                endTime = parseInt(splitEnd[0]) * 60 + parseInt(splitEnd[1]);
            if (ignoreValid) {
                if (startTime !== endTime) {
                    onChange(val);
                }
            } else if (startTime < endTime && startTime < 1440 && endTime <= 1440) {
                onChange(val);
            }
        }
    };

    const handleBlur = (e) => {
        setValue(props.value);
    };

    return (
        <div ref={ref} className={`time-range-field`} onClick={handleClick} attr-data-memo={isNextDay ? `(${intl.formatMessage({id: 'the_next_day'})})` : ''}>
            <Input  id={id} value={value} disabled={disabled} onChange={handleChange} onBlur={handleBlur} />
        </div>
    );
});

export function TimeRangeField({id, disabled, ignoreValid, onChange, ...props}) {
    const [value, setVlaue] = useState('');
    const [isNextDay, setIsNextDay] = useState(false);
    const pickerRef = useRef(null);

    const validTimeValue = useCallback(() => {
        return (props.startTime > 0 || props.endTime) && props.startTime < 1440 && props.endTime <= 1440;
    }, [props.startTime, props.endTime]);

    useEffect(() => {
        if (props.value) {
            setVlaue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (validTimeValue()) {
            var _val = format('{0}:{1} - {2}:{3}', Math.floor(props.startTime / 60).toString().padStart(2, '0'), Math.floor(props.startTime % 60).toString().padStart(2, '0'),
                        Math.floor(props.endTime / 60).toString().padStart(2, '0'), Math.floor(props.endTime % 60).toString().padStart(2, '0'));
            setVlaue(_val);
            setIsNextDay(props.endTime < props.startTime);
        }       
    }, [props.startTime, props.endTime, validTimeValue]);

    const handleChange = val => {
        if (!val.includes(' - ')) return;

        if (validTimeValue()) {
            var strStart = val.split(' - ')[0],
                strEnd = val.split(' - ')[1],
                splitStart = strStart.split(':'),
                splitEnd = strEnd.split(':');

            var startTime = parseInt(splitStart[0]) * 60 + parseInt(splitStart[1]),
                endTime = parseInt(splitEnd[0]) * 60 + parseInt(splitEnd[1]);
            onChange(val, startTime, endTime);
        } else {
            onChange(val);
        }
    };

    const handleToggle = (nextShow) => {
        if (nextShow) {
            setTimeout(() => {
                if (pickerRef.current) {
                    pickerRef.current.scroll();
                }
            }, 100);
        }
    };

    return (
        <Popover overlayClassName='time-range-dropdown' placement="bottomLeft" arrow={false} trigger={['click']} onOpenChange={handleToggle} getPopupContainer={props.getPopupContainer}
            content={
                <TimeRangePicker ref={pickerRef} ignoreValid={ignoreValid} value={value} onChange={handleChange} />
            }
        >
            <FieldToggle id={id} value={value} disabled={disabled} ignoreValid={ignoreValid} isNextDay={isNextDay} onChange={handleChange} />
        </Popover>
    );
}
TimeRangeField.defaultProps = {
    id: '',
    disabled: false,
    getPopupContainer: null,
    value: '00:00 - 24:00',
    startTime: 0,   // min: 0, max: 1435
    endTime: 0,     // min: 5, max: 1440
    ignoreValid: false,
    onChange: function(value, startTime, endTime) {}
};

export function PasswordField({showEye, fakePassword, value, ...props}) {
    return (
        <Input.Password {...props} value={fakePassword ? '********' : value} visibilityToggle={showEye} autoComplete={`new-${props.name || 'password'}`}
            iconRender={(visible) => visible ? <SVGIcon.PasswordHide /> : <SVGIcon.PasswordShow />}
        />
    );
}
PasswordField.defaultProps = {
    value: '',
    showEye: true,
    fakePassword: false
};

export const enumNumberCodeType = {
    PinCode: 0,
    CommonPassword: 1
};
export const NumberCodeField = forwardRef(({type, value, onChange, ...props}, ref) => {
    const fakePwd = useMemo(() => {
        return value && value.includes('*');
    }, [value]);

    const dipValue = useMemo(() => {
        if (fakePwd) {
            return type === enumNumberCodeType.PinCode ? '****' : '********';
        } else {
            return value;
        }
    }, [value, fakePwd, type]);

    const handleChange = e => {
        var value = e.target.value,
            strNum = '', i;
        
        for (i = 0; i < value.length; i++) {
            if (/^\d+$/.test(value[i])) {
                strNum += value[i];
            }
        }
        onChange(strNum);
    };

    return (
        <Input.Password ref={ref} {...props} value={dipValue} onChange={handleChange}
            visibilityToggle={!fakePwd} autoComplete="one-time-code"
            iconRender={(visible) => visible ? <SVGIcon.PasswordHide /> : <SVGIcon.PasswordShow />}
        />
    );
});
NumberCodeField.defaultProps = {
    type: enumNumberCodeType.PinCode,
    value: '',
    onChange: function() {}
};

export function LogViewMode(props) {
    return (
        <SegmentedEx size='sm' className='common-log-view-mode' {...props}
            options={[{
                value: EnumLogViewMode.Photo,
                icon: (
                    <Tooltip placement='bottom' title={<FormattedMessage id="photo_view" defaultMessage="Photo View" />}>
                        <SVGIcon.QueryPhotoMode />
                    </Tooltip>
                )
            }, {
                value: EnumLogViewMode.List,
                icon: (
                    <Tooltip placement='bottom' title={<FormattedMessage id="list_view" defaultMessage="List View" />}>
                        <SVGIcon.QueryListMode />
                    </Tooltip>
                )
            }]}
        />
    )
}
LogViewMode.defaultProps = {
    value: EnumLogViewMode.Photo,
    onChange: function(value) {}
};

export const getTreeSwitcherIcon = (nodeProps) => {
    return <SVGIcon.ArrowDropDown className={nodeProps.expanded ? 'expanded' : ''} />;
};

export const SearchInput = (props) => {
    return (
        <Input {...props} className={`common-search-input ${props.className || ''}`} suffix={<SVGIcon.Search />} allowClear={true} />
    );
};

export const TargetDropdown = ({target, onClose, ...props}) => {
    const targetStyle = useMemo(() => {
        if (target) {
            var rect = target.getBoundingClientRect();
            return {
                left: `${(rect.left + rect.right) / 2}px`,
                top: `${rect.top}px`,
                height: `${rect.height}px`
            };
        } else {
            return {
                display: 'none'
            };
        }
    }, [target]);

    const handleOpenChange = (open) => {
        if (!open) {
            onClose();
        }
    };
    
    return (
        <Dropdown {...props} open={!!target} onOpenChange={handleOpenChange}>
            <div style={targetStyle} className='target-dropdown-pos'></div>
        </Dropdown>
    );
};
TargetDropdown.defaultProps = {
    target: null,
    onClose: function() {},

    menu: {},
    placement: 'bottom',
    arrow: {pointAtCenter: true},
    trigger: ['click']
};

export const TooltipExt = ({forceHide, children, ...props}) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (forceHide) {
            setOpen(false);
        }
    }, [forceHide]);

    return (
        <Tooltip {...props} trigger={forceHide ? [] : ['hover']} open={open} onOpenChange={(value) => setOpen(value)}>
            {children}
        </Tooltip>
    );
};
TooltipExt.defaultProps = {
    forceHide: false
};