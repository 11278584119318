import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { format } from 'react-string-format';
import { FormattedMessage } from "react-intl";
import { Input, Popover } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons'
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { eachDayOfInterval } from 'date-fns';
import { FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";
import { AppContext, ExcludeProps } from '../../Utils';
import SVGIcon from '../../icons.js';
import './DateRangePicker.css';

const EnumDateRange = {
    TODAY: 0,
    YESTERDAY: 1,
    THIS_WEEK: 2,
    LAST_WEEK: 3,
    THIS_MONTH: 4,
    LAST_MONTH: 5,
    LATEST_3DAYS: 6,
    LATEST_WEEK: 7,
    LATEST_MONTH: 8
};
const scrollIntoViewOptions = {behavior: 'smooth', block: 'center', inline: 'center'};
const MaxMonthRange = 3;

const DateFiledToggle = React.forwardRef(({dateFormat, timeFormat, allowClear, value, onChange, ...props}, ref) => {
    const [orig, setOrig] = useState(value);
    const [dispValue, setDispValue] = useState(value);

    useEffect(() => {
        if (value !== orig) {
            setOrig(value);
            setDispValue(value);
        }
    }, [value, orig]);

    const handleFieldChange = e => {
        var str = e.target.value,
            date = moment(str, dateFormat + ' ' + timeFormat, true);
        
        if (allowClear && !str) {
            onChange('');
            return;
        }

        setDispValue(str);
        if (date.isValid() && date.year() > 1910 && date.year() < 2400) {
            if ((!props.min && !props.max) || (props.min && date >= moment(props.min)) || (props.max && date <= moment(props.max))) {
                onChange(date.format(dateFormat + ' ' + timeFormat));
            }
        }
    };

    const handleFieldBlur = e => {
        if (dispValue !== orig) {
            setDispValue(orig);
        }
    };

    return (
        <Input {...props} ref={ref} autoComplete='off' suffix={props.disabled ? null : <SVGIcon.DatePicker />} allowClear={allowClear ? {clearIcon: <CloseCircleFilled />} : false}
            value={dispValue} onChange={handleFieldChange} onBlur={handleFieldBlur}
        />
    );
});
DateFiledToggle.defaultProps = {
    value: null,
    disabled: false,
    dateFormat: 'MM-DD-YYYY',
    timeFormat: 'HH:mm:ss',
    size: '',
    allowClear: false,
    min: '',
    max: '',
    onChange: function(date) {}
};

const timeCellHeight = 28;
const DateDropdownMenu = React.forwardRef(({dateFormat, timeFormat, onChange, ...props}, ref) => {
    const [pickDate, setPickDate] = useState(null);   // selected date
    const [viewDate, setViewDate] = useState(moment());   // view date
    const [isPickYear, setIsPickYear] = useState(false);

    const hourPickerPanel = useRef(null);
    const minutePickerPanel = useRef(null);
    const secondPickerPanel = useRef(null);
    const yearPickerPanel = useRef(null);
    const scrollTimer = useRef();

    const defaultTime = props.isStart ? '00:00:00' : '23:59:59';

    const minDate = useMemo(() => {
        return props.min ? moment(props.min) : null;
    }, [props.min]);

    const maxDate = useMemo(() => {
        return props.max ? moment(props.max) : null;
    }, [props.max]);

    const handleChange = useCallback((date, replace) => {
        setViewDate(date);

        if (minDate || maxDate) {
            if (minDate && date < minDate) {
                if (replace) {
                    date = minDate;
                } else return;
            } else if (maxDate && date > maxDate) {
                if (replace) {
                    date = maxDate;
                } else return;
            }
        }

        setPickDate(date);
        onChange(date.format(dateFormat + ' ' + timeFormat));
    }, [dateFormat, timeFormat, onChange, minDate, maxDate]);

    const handleTimeWheel = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = false; 

        var elPanel = e.target.closest('.time-picker-panel'),
            elTime,
            name = elPanel.getAttribute('name'),
            step = Math.round(e.deltaY * 0.01),
            date = pickDate ? pickDate.clone() : moment().startOf('date');
        switch (name) {
            case 'hour':
                step = Math.min(Math.max(step, 0 - date.hour()), 23 - date.hour());
                date.add(step, 'hour');
                elTime = elPanel.querySelector(`[eventkey="${date.format('HH')}"]`);
                if (hourPickerPanel.current && elTime && !elTime.classList.contains('disabled')) {
                    hourPickerPanel.current.scrollTo({top: date.hour() * timeCellHeight});
                }
                break;
            case 'minute':
                step = Math.min(Math.max(step, 0 - date.minute()), 59 - date.minute());
                date.add(step, 'minute');
                elTime = elPanel.querySelector(`[eventkey="${date.format('mm')}"]`);
                if (minutePickerPanel.current && elTime && !elTime.classList.contains('disabled')) {
                    minutePickerPanel.current.scrollTo({top: date.minute() * timeCellHeight});
                }
                break;
            case 'second':
                step = Math.min(Math.max(step, 0 - date.second()), 59 - date.second());
                date.add(step, 'second');
                elTime = elPanel.querySelector(`[eventkey="${date.format('ss')}"]`);
                if (secondPickerPanel.current && elTime && !elTime.classList.contains('disabled')) {
                    secondPickerPanel.current.scrollTo({top: date.second() * timeCellHeight});
                }
                break;
            default:
                return;
        }
        handleChange(date);
    }, [handleChange, pickDate]);

    useEffect(() => {
        let observerRefValue = null;
        if (hourPickerPanel.current) {
            observerRefValue = hourPickerPanel.current;
            hourPickerPanel.current.addEventListener('wheel', handleTimeWheel, { passive: false});
        }
        return () => {
            if (observerRefValue) {
                observerRefValue.removeEventListener('wheel', handleTimeWheel);
            }
        };
    }, [hourPickerPanel, handleTimeWheel]);

    useEffect(() => {
        let observerRefValue = null;
        if (minutePickerPanel.current) {
            observerRefValue = minutePickerPanel.current;
            minutePickerPanel.current.addEventListener('wheel', handleTimeWheel, { passive: false});
        }
        return () => {
            if (observerRefValue) {
                observerRefValue.removeEventListener('wheel', handleTimeWheel);
            }
        };
    }, [minutePickerPanel, handleTimeWheel]);

    useEffect(() => {
        let observerRefValue = null;
        if (secondPickerPanel.current) {
            observerRefValue = secondPickerPanel.current;
            secondPickerPanel.current.addEventListener('wheel', handleTimeWheel, { passive: false});
        }
        return () => {
            if (observerRefValue) {
                observerRefValue.removeEventListener('wheel', handleTimeWheel);
            }
        };
    }, [secondPickerPanel, handleTimeWheel]);

    useEffect(() => {
        var date = moment(props.value, `${dateFormat} ${timeFormat}`);
        if (props.value && date.isValid()) {
            setPickDate(date);
            setViewDate(date);

            if (props.show) {
                if (scrollTimer.current) {
                    clearTimeout(scrollTimer.current);
                    scrollTimer.current = null;
                }
                scrollTimer.current = setTimeout(() => {
                    if (hourPickerPanel.current) {
                        hourPickerPanel.current.scrollTo({top: date.hour() * timeCellHeight, behavior: 'smooth'});
                    }
                    if (minutePickerPanel.current) {
                        minutePickerPanel.current.scrollTo({top: date.minute() * timeCellHeight, behavior: 'smooth'});
                    }
                    if (secondPickerPanel.current) {
                        secondPickerPanel.current.scrollTo({top: date.second() * timeCellHeight, behavior: 'smooth'});
                    }
                    scrollTimer.current = null;
                }, 100);
            }
        } else {
            setPickDate(null);
            setViewDate(moment());
        }
    }, [props.value, props.show, dateFormat, timeFormat]);

    useEffect(() => {
        if (isPickYear && yearPickerPanel.current) {
            setTimeout(() => {
                var el = yearPickerPanel.current.querySelector('.selected');
                if (el) {
                    el.scrollIntoView(scrollIntoViewOptions);
                }
                
            }, 100);
        }
    }, [isPickYear]);

    useEffect(() => {
        setIsPickYear(false);
    }, [props.show]);

    const handleRangeChange = type => {
        var start = moment(),
            end = moment();
        switch (type) {
            case EnumDateRange.TODAY:
                start.startOf('day');
                end.endOf('day');
                break;
            case EnumDateRange.YESTERDAY:
                start.subtract(1, 'days').startOf('day');
                end.subtract(1, 'days').endOf('day');
                break;
            case EnumDateRange.THIS_WEEK:
                start.startOf('week');
                end.endOf('week');
                break;
            case EnumDateRange.LAST_WEEK:
                start.subtract(1, 'weeks').startOf('week');
                end.subtract(1, 'weeks').endOf('week');
                break;
            case EnumDateRange.THIS_MONTH:
                start.startOf('month');
                end.endOf('month');
                break;
            case EnumDateRange.LAST_MONTH:
                start.subtract(1, 'months').startOf('month');
                end.subtract(1, 'months').endOf('month');
                break;
            case EnumDateRange.LATEST_3DAYS:
                start.subtract(3, 'days').startOf('day');
                end.endOf('day');
                break;
            case EnumDateRange.LATEST_WEEK:
                start.subtract(1, 'weeks').startOf('day');
                end.endOf('day');
                break;
            case EnumDateRange.LATEST_MONTH:
                start.subtract(1, 'months').startOf('day');
                end.endOf('day');
                break;
            default:
                break;
        }
        setIsPickYear(false);
        props.onRangeChange(start.format(dateFormat + ' ' + timeFormat), end.format(dateFormat + ' ' + timeFormat));
        props.onClose();
    };

    const handleSwitchMonth = step => {
        var strSwitchDate = moment(viewDate).add(step, 'months').format(dateFormat) + ' ' +
                            (pickDate ? pickDate.format(timeFormat) : defaultTime);
        handleChange(moment(strSwitchDate, `${dateFormat} ${timeFormat}`));
    };

    const handleSwitchYear = step => {
        var strSwitchDate = moment(viewDate).add(step, 'years').format(dateFormat) + ' ' +
                            (pickDate ? pickDate.format(timeFormat) : defaultTime);
        handleChange(moment(strSwitchDate, `${dateFormat} ${timeFormat}`));
    };

    const handleDateClick = e => {
        var str = e.currentTarget.attributes.getNamedItem('eventkey').value,
            date = moment(format('{0} {1}', str, pickDate? pickDate.format(timeFormat) : defaultTime), `${dateFormat} ${timeFormat}`);
        handleChange(date, true);
    };

    const handleTimeClick = (unit, step) => {
        var date = pickDate ? pickDate.clone() : moment(moment().format(dateFormat) + ' ' + defaultTime, `${dateFormat} ${timeFormat}`);
        switch (unit) {
            case 'h':
                date = date.set('hours', step);
                break;
            case 'm':
                date = date.set('minutes', step);
                break;
            case 's':
                date = date.set('seconds', step);
                break;
            default:
                break;
        }
        handleChange(date, true);
    };

    const handleYearClick = e => {
        var year = parseInt(e.currentTarget.attributes.getNamedItem('eventkey').value);
        var switchDate = pickDate || moment(viewDate.format(dateFormat) + ' ' + defaultTime, `${dateFormat} ${timeFormat}`);

        switchDate = switchDate.set('year', year);
        handleChange(switchDate);
        setIsPickYear(false);
    };

    const handleMonthClick = e => {
        var month = parseInt(e.currentTarget.attributes.getNamedItem('eventkey').value);
        var switchDate = pickDate || moment(viewDate.format(dateFormat) + ' ' + defaultTime, `${dateFormat} ${timeFormat}`);

        switchDate = switchDate.set('month', month);
        handleChange(switchDate);
        setIsPickYear(false);
    };

    const handleWheel = e => {
        e.preventDefault();
        var step = e.deltaY * 0.01;
        handleSwitchMonth(step);
    };

    return (
        <div {...ExcludeProps(props, 'value', 'isStart', 'showRange', 'onChange', 'onRangeChange', 'show', 'close')} ref={ref}>
            <div className={`daterange-dropdown-menu ${props.showRange ? 'with-range' : ''}`}>
                {
                    props.showRange &&
                    <div className='range-picker'>
                        <div onClick={e => handleRangeChange(EnumDateRange.TODAY)}><FormattedMessage id="today" defaultMessage="Today" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.YESTERDAY)}><FormattedMessage id="yesterday" defaultMessage="Yesterday" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.THIS_WEEK)}><FormattedMessage id="this_week" defaultMessage="This Week" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.LAST_WEEK)}><FormattedMessage id="last_week" defaultMessage="Last Week" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.THIS_MONTH)}><FormattedMessage id="this_month" defaultMessage="This Month" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.LAST_MONTH)}><FormattedMessage id="last_month" defaultMessage="Last Month" /></div>
                        <div className='separator'></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.LATEST_3DAYS)}><FormattedMessage id="latest_3_days" defaultMessage="Latest 3 Days" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.LATEST_WEEK)}><FormattedMessage id="latest_week" defaultMessage="Latest Week" /></div>
                        <div onClick={e => handleRangeChange(EnumDateRange.LATEST_MONTH)}><FormattedMessage id="latest_month" defaultMessage="Latest Month" /></div>
                    </div>
                }
                <div className='daterange-picker-panel'>
                    <div className={'daterange-picker-main-panel' + (isPickYear? ' hide' : '')}>
                        <div className='date-picker'>
                            <div className='date-picker-control' onWheel={handleWheel}>
                                <div className='date-picker-control-switch' onClick={e => handleSwitchYear(-1)}><FaAngleDoubleLeft /></div>
                                <div className='date-picker-control-switch' onClick={e => handleSwitchMonth(-1)}><FaAngleLeft /></div>
                                <div className='date-picker-title' onClick={e => setIsPickYear(true)}>
                                    {moment().year(viewDate.year()).month(viewDate.month()).format(dateFormat.indexOf('YYYY') < dateFormat.indexOf('MM') ? 'YYYY MMM' : 'MMM YYYY')}
                                </div>
                                <div className='date-picker-control-switch' onClick={e => handleSwitchMonth(1)}><FaAngleRight /></div>
                                <div className='date-picker-control-switch' onClick={e => handleSwitchYear(1)}><FaAngleDoubleRight /></div>
                            </div>
                            <div className='date-picker-weeks' onWheel={handleWheel}>
                            {
                                eachDayOfInterval({start: moment(viewDate).startOf('week').toDate(), end: moment(viewDate).endOf('week').toDate()}).map((day, i) => 
                                    <span key={i}>
                                        {moment.weekdaysMin(day.getDay())}
                                    </span>
                                )
                            }
                            </div>
                            <div className='date-picker-days' onWheel={handleWheel}>
                            {
                                eachDayOfInterval({start: moment(viewDate).startOf('month').startOf('week').toDate(), end: moment(viewDate).endOf('month').endOf('week').toDate()}).map((day, index) => {
                                    var selected = pickDate && pickDate.year() === day.getFullYear() && pickDate.month() === day.getMonth() && pickDate.date() === day.getDate();
                                    var key = moment(day).format(dateFormat);
                                    var disabled = (minDate && moment(day).endOf('day') < minDate) || (maxDate && moment(day).startOf('day') > maxDate);
                                    return (
                                        <div className={`date-picker-day-cell ${viewDate.month() !== day.getMonth()? 'diff-month' : ''} ${selected? 'selected' : ''} ${disabled? 'disabled' : ''}`} 
                                            onClick={handleDateClick} eventkey={key} key={key}>
                                            {day.getDate().toString()}
                                        </div>
                                    );
                                })
                            }
                            </div>
                        </div>
                        <div className='time-picker'>
                            {
                                timeFormat.includes('HH') &&
                                <div className='time-picker-column'>
                                    <div className='time-picker-header'>
                                        <span><FormattedMessage id="hour_short" defaultMessage="hr." /></span>
                                    </div>
                                    <div ref={hourPickerPanel} name='hour' className='time-picker-panel'>
                                    {
                                        (Array.from(Array(24).keys())).map((item, idx) => {
                                            var selected = pickDate && pickDate.hour() === idx,
                                                key = idx.toString().padStart(2, '0');
                                            var disabled = (pickDate && minDate && pickDate.format(dateFormat) === minDate.format(dateFormat) && idx < minDate.hour()) ||
                                                           (pickDate && maxDate && pickDate.format(dateFormat) === maxDate.format(dateFormat) && idx > maxDate.hour());
                                            return <div key={idx} className={`${selected? 'selected' : ''} ${disabled? 'disabled' : ''}`} onClick={e => handleTimeClick('h', idx)} eventkey={key}>
                                                        {key}
                                                    </div>;
                                        })
                                    }
                                    </div>
                                </div>
                            }
                            {
                                timeFormat.includes('mm') &&
                                <div className='time-picker-column'>
                                    <div className='time-picker-header'>
                                        <span><FormattedMessage id="minute_short" defaultMessage="min." /></span>
                                    </div>
                                    <div ref={minutePickerPanel} name='minute' className='time-picker-panel'>
                                    {
                                        (Array.from(Array(60).keys())).map((item, idx) => {
                                            var selected = pickDate && pickDate.minute() === idx,
                                                key = idx.toString().padStart(2, '0');
                                            var timeFormat = 'yyyyMMddHH',
                                                disabled = (pickDate && minDate && pickDate.format(timeFormat) === minDate.format(timeFormat) && idx < minDate.minute()) ||
                                                           (pickDate && maxDate && pickDate.format(timeFormat) === maxDate.format(timeFormat) && idx > maxDate.minute());
                                            return <div key={idx} className={`${selected? 'selected' : ''} ${disabled? 'disabled' : ''}`} onClick={e => handleTimeClick('m', idx)} eventkey={key}>
                                                        {key}
                                                    </div>;
                                        })
                                    }
                                    </div>
                                </div>
                            }
                            {
                                timeFormat.includes('ss') &&
                                <div className='time-picker-column'>
                                    <div className='time-picker-header'>
                                        <span><FormattedMessage id="second_short" defaultMessage="sec." /></span>
                                    </div>
                                    <div ref={secondPickerPanel} name='second' className='time-picker-panel'>
                                    {
                                        (Array.from(Array(60).keys())).map((item, idx) => {
                                            var selected = pickDate && pickDate.second() === idx,
                                                key = idx.toString().padStart(2, '0');
                                            var timeFormat = 'yyyyMMddHHmm',
                                                disabled = (pickDate && minDate && pickDate.format(timeFormat) === minDate.format(timeFormat) && idx < minDate.second()) ||
                                                           (pickDate && maxDate && pickDate.format(timeFormat) === maxDate.format(timeFormat) && idx > maxDate.second());
                                            return <div key={idx} className={`${selected? 'selected' : ''} ${disabled? 'disabled' : ''}`} onClick={e => handleTimeClick('s', idx)} eventkey={key}>
                                                        {key}
                                                    </div>;
                                        })
                                    }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={'daterange-picker-sub-panel' + (isPickYear? ' show' : '')}>
                        <div ref={yearPickerPanel} className='year-picker scroll-box'>
                        {
                            (Array.from(Array(200).keys())).map((item, idx) => {
                                var year = 1910 + idx;
                                var disabled = (minDate && year < minDate.year()) || (maxDate && year > maxDate.year());
                                return <div key={year} className={`${year === viewDate.year()? 'selected' : ''} ${disabled? 'disabled' : ''}`} onClick={handleYearClick} eventkey={year}>
                                            {year}
                                        </div>;
                            })
                        }
                        </div>
                        <div className='month-picker'>
                            <div className='month-picker-column'>
                            {
                                (Array.from(Array(6).keys())).map((item, idx) => {
                                    var month = idx;
                                    var disabled = (pickDate && minDate && pickDate.year() === minDate.year() && month < minDate.month()) ||
                                                   (pickDate && maxDate && pickDate.year() === maxDate.year() && month > maxDate.month());
                                    return <div key={month} className={`${month === viewDate.month()? 'selected' : ''}  ${disabled? 'disabled' : ''}`} onClick={handleMonthClick} eventkey={month}>
                                                {moment().month(month).format('MMM')}
                                            </div>;
                                })
                            }
                            </div>
                            <div className='month-picker-column'>
                            {
                                (Array.from(Array(6).keys())).map((item, idx) => {
                                    var month = idx + 6;
                                    var disabled = (pickDate && minDate && pickDate.year() === minDate.year() && month < minDate.month()) ||
                                                   (pickDate && maxDate && pickDate.year() === maxDate.year() && month > maxDate.month());
                                    return <div key={month} className={`${month === viewDate.month()? 'selected' : ''}  ${disabled? 'disabled' : ''}`} onClick={handleMonthClick} eventkey={month}>
                                                {moment().month(month).format('MMM')}
                                            </div>;
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
DateDropdownMenu.defaultProps = {
    show: false,
    value: null,
    isStart: false,
    showRange: false,
    dateFormat: 'MM-DD-YYYY',
    timeFormat: 'HH:mm:ss',
    min: '',
    max: '',
    onChange: function(date) {},
    onRangeChange: function(startDate, endDate) {},
    onClose: function() {}
};

export function ASDateField(props) {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <Popover overlayClassName='daterange-popover' placement="bottomLeft" arrow={false} trigger={props.disabled ? [] : ['click']} getPopupContainer={props.getPopupContainer}
            open={showMenu} onOpenChange={open => setShowMenu(open)}
            content={
                <div style={{width: props.showRange ? '530px' : '400px'}}>
                    <DateDropdownMenu isStart={props.isStart} showRange={props.showRange} dateFormat={props.dateFormat} timeFormat={props.timeFormat} min={props.min} max={props.max} show={showMenu}
                        value={props.value} onChange={props.onChange} onRangeChange={props.onRangeChange} onClose={() => setShowMenu(false)} />
                </div>
            }
        >
            <div className='daterange-input-group'>
                <DateFiledToggle className={props.className} name={props.name} type="text" size={props.size} placeholder={props.placeholder} dateFormat={props.dateFormat} timeFormat={props.timeFormat}
                    id={props.fieldId} min={props.min} max={props.max} allowClear={props.allowClear}
                    value={props.value} onChange={props.onChange} disabled={props.disabled} />
            </div>
        </Popover>
    );
}
ASDateField.defaultProps = {
    value: '',
    name: '',
    className: '',
    placeholder: '',
    isStart: false,
    showRange: false,
    disabled: false,
    getPopupContainer: null,
    dateFormat: 'MM-DD-YYYY',
    timeFormat: 'HH:mm:ss',
    onChange: function(strDate) {},
    onRangeChange: function(startDate, endDate) {},

    size: '',
    allowClear: false,
    fieldId: null,
    min: '',    // YYYY-MM-DD HH:mm:ss
    max: ''     // YYYY-MM-DD HH:mm:ss
};

export default function ASDateRange(props) {
    const { accountInfo } = useContext(AppContext);    
    const enumInvalidDateRange = {
        upsideDown: 1,
        outOfRange: 2
    };

    const dateFormat = useMemo(() => {
        var timeFormat = 'MM-DD-YYYY';
        if (accountInfo?.time_format) {
            timeFormat = accountInfo?.time_format.toString().split(' ')?.[0] || 'MM-DD-YYYY';
        }
        return timeFormat;
    }, [accountInfo.time_format]);

    const startDate = useMemo(() => {
        return moment(props.startDate, 'YYYY-MM-DD HH:mm:ss').format(`${dateFormat} HH:mm:ss`);
    }, [props.startDate, dateFormat]);

    const endDate = useMemo(() => {
        return moment(props.endDate, 'YYYY-MM-DD HH:mm:ss').format(`${dateFormat} HH:mm:ss`);
    }, [props.endDate, dateFormat]);

    const validatorDateRange = (strStartDate, strEndDate) => {
        var startDate = moment(strStartDate, `${dateFormat} HH:mm:ss`),
            endDate = moment(strEndDate, `${dateFormat} HH:mm:ss`);

        if (startDate.isValid() && endDate.isValid()) {
            if (startDate >= endDate) {
                return enumInvalidDateRange.upsideDown;
            } else {
                var maxEndDate = startDate.add(MaxMonthRange, 'months');
                if (endDate > maxEndDate) {
                    return enumInvalidDateRange.outOfRange;
                }
            }
        }
        return null;
    };

    const handleChange = (startDate, endDate, invalid) => {
        props.onChange(moment(startDate, `${dateFormat} HH:mm:ss`).format('YYYY-MM-DD HH:mm:ss'), moment(endDate, `${dateFormat} HH:mm:ss`).format('YYYY-MM-DD HH:mm:ss'), invalid);
    };

    const handleSatartDateChange = date => {
        var end = moment(endDate, `${dateFormat} HH:mm:ss`);
        switch (validatorDateRange(date, end)) {
            case enumInvalidDateRange.upsideDown:
                end = moment(date, `${dateFormat} HH:mm:ss`).format(dateFormat) + ' 23:59:59';
                break;
            case enumInvalidDateRange.outOfRange:
                end = moment(date, `${dateFormat} HH:mm:ss`).add(MaxMonthRange, 'months').format(dateFormat) + ' 23:59:59';
                break;
            default:
                break;
        }

        handleChange(date, end);
    };

    const handelEndDateChange = date => {
        var start = moment(startDate);
        switch (validatorDateRange(start, date)) {
            case enumInvalidDateRange.upsideDown:
                start = moment(date, `${dateFormat} HH:mm:ss`).format(dateFormat) + ' 00:00:00';
                break;
            case enumInvalidDateRange.outOfRange:
                start = moment(date, `${dateFormat} HH:mm:ss`).subtract(MaxMonthRange, 'months').format(dateFormat) + ' 00:00:00';
                break;
            default:
                break;
        }

        handleChange(start, date);
    };

    return (
        <>
            <Col md={props.colSize}>
                <ASDateField className={props.className} name='startDate' dateFormat={dateFormat} placeholder={props.startPlaceholder} disabled={props.disabled} isStart={true} showRange={true}
                    value={startDate} onChange={handleSatartDateChange} onRangeChange={handleChange}
                />
            </Col>
            <Col md={props.colSize}>
                <ASDateField className={props.className} name='endDate' dateFormat={dateFormat} placeholder={props.endPlaceholder} disabled={props.disabled}
                    value={endDate} onChange={handelEndDateChange} onRangeChange={handleChange}
                />
            </Col>
        </>
    );
}

ASDateRange.defaultProps = {
    colSize: 3,
    className: '',
    startPlaceholder: '',
    endPlaceholder: '',
    startDate: '',      // YYYY-MM-DD HH:mm:ss
    endDate: '',        // YYYY-MM-DD HH:mm:ss
    disabled: false,
    onChange: function(startDate, endDate) {}
};

export function ASActiveDateRange({onChange, ...props}) {
    const { accountInfo } = useContext(AppContext);

    const dateFormat = useMemo(() => {
        var timeFormat = 'MM-DD-YYYY';
        if (accountInfo?.time_format) {
            timeFormat = accountInfo?.time_format.toString().split(' ')?.[0] || 'MM-DD-YYYY';
        }
        return timeFormat;
    }, [accountInfo.time_format]);

    const startDate = useMemo(() => {
        return props.startDate ? moment(props.startDate, 'YYYY-MM-DD HH:mm:ss').format(`${dateFormat} HH:mm`) : '';
    }, [props.startDate, dateFormat]);

    const endDate = useMemo(() => {
        return props.endDate ? moment(props.endDate, 'YYYY-MM-DD HH:mm:ss').format(`${dateFormat} HH:mm`) : '';
    }, [props.endDate, dateFormat]);

    const handleChange = useCallback((field, value) => {
        var data = {};
        data[field] = value ? moment(value, `${dateFormat} HH:mm`).format('YYYY-MM-DD HH:mm') : '';
        onChange(data);
    }, [dateFormat, onChange]);

    const startField = useMemo(() => {
        return (
            <ASDateField name="ao_start_time" placeholder={props.startPlaceholder} dateFormat={dateFormat} timeFormat='HH:mm' size={props.size} allowClear={true} isStart={true} disabled={props.disabled}
                getPopupContainer={props.getPopupContainer} fieldId={props.startFieldId} max={endDate} value={startDate} onChange={value => handleChange('ao_start_time', value)}
            />
        );
    }, [props.startPlaceholder, props.startFieldId, props.size, props.disabled, props.getPopupContainer, startDate, endDate, dateFormat, handleChange]);

    const endField = useMemo(() => {
        return (
            <ASDateField name="ao_end_time" placeholder={props.endPlaceholder} dateFormat={dateFormat} timeFormat='HH:mm' size={props.size} allowClear={true} disabled={props.disabled}
                getPopupContainer={props.getPopupContainer} fieldId={props.endFieldId} min={startDate} value={endDate} onChange={value => handleChange('ao_end_time', value)}
            />
        );
    }, [props.endPlaceholder, props.endFieldId, props.size, props.disabled, props.getPopupContainer, startDate, endDate, dateFormat, handleChange]);

    return props.children(startField, endField);
}
ASActiveDateRange.defaultProps = {
    startPlaceholder: '',
    endPlaceholder: '',
    startDate: '',      // YYYY-MM-DD HH:mm:ss
    endDate: '',        // YYYY-MM-DD HH:mm:ss
    startFieldId: null,
    endFieldId: null,
    size: '',
    disabled: false,
    getPopupContainer: null,
    onChange: function({ao_start_time, ao_end_time}) {}
};