import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { format } from 'react-string-format';
import { Button, Row, Col, Card } from 'react-bootstrap';

import { Modal, Tree, Tooltip, Form, AutoComplete, Input } from 'antd';

import { EnumASCloudAPIType, EnumActionType, EnumAccountType } from '../ASUtils/ASConfig';
import { AppContext, Constants } from '../../Utils';
import { ShowAlertMessage, LoadingMask, getTreeSwitcherIcon, TargetDropdown } from '../../modules/Common/Common.js';
import { enumUserFieldsMaxLength } from './UserSetting';
import SVGIcon from '../../icons.js';
import './Organization.css';

export const OrganizationFields = forwardRef((props, ref) => {
    const intl = useIntl();
    const context = useContext(AppContext);
    const form = Form.useFormInstance();

    const u_og1 = Form.useWatch('u_og1', form);
    const u_og2 = Form.useWatch('u_og2', form);
    const u_og3 = Form.useWatch('u_og3', form);

    const [showOrgSetting, setShowOrgSetting] = useState(false);
    const [listOg1, setListOg1] = useState([]);
    const [listOg2, setListOg2] = useState([]);
    const [listOg3, setListOg3] = useState([]);

    const [ogTitle1, setOgTitle1] = useState('');
    const [ogTitle2, setOgTitle2] = useState('');
    const [ogTitle3, setOgTitle3] = useState('');
    
    useEffect(() => {
        if (Array.isArray(props.og_titles) && props.og_titles.length === 3) {
            setOgTitle1(props.og_titles[0]);
            setOgTitle2(props.og_titles[1]);
            setOgTitle3(props.og_titles[2]);
        }
    }, [props.og_titles]);

    useEffect(() => {
        if (Array.isArray(props.og_map)) {
            var list = [];
            props.og_map.forEach(item => list.push({value: item.og_name}));
            setListOg1(list);
        }
    }, [props.og_map]);

    useEffect(() => {
        var list = [];
        var tmp = props.og_map.find(item => item.og_name === u_og1);
        if (tmp && Array.isArray(tmp.children)) {
            tmp.children.forEach(item => list.push({value: item.og_name}));
        }
        setListOg2(list);
    }, [u_og1, props.og_map]);

    useEffect(() => {
        var list = [];
        var tmp = props.og_map.find(item => item.og_name === u_og1);
        if (tmp && Array.isArray(tmp.children)) {
            tmp = tmp.children.find(item => item.og_name === u_og2);
            if (tmp && Array.isArray(tmp.children)) {
                tmp.children.forEach(item => list.push({value: item.og_name}));
            }
        }
        setListOg3(list);
    }, [u_og1, u_og2, props.og_map]);

    useImperativeHandle(ref, () => ({
        getOrganizationIds() {
            var fields = {
                u_og1: u_og1.trim(),
                u_og2: u_og2.trim(),
                u_og3: u_og3.trim()
            };
            fields.u_og1_id = (fields.u_og1 || fields.u_og2 || fields.u_og3) ? -1 : 0;
            fields.u_og2_id = (fields.u_og2 || fields.u_og3) ? -1 : 0;
            fields.u_og3_id = (fields.u_og3) ? -1 : 0;

            if (fields.u_og1 || fields.u_og2 || fields.u_og3) {
                var level1 = props.og_map.find(item => item.og_name === fields.u_og1);
                if (level1) {
                    fields.u_og1_id = level1.og_id;
                    var level2 = level1.children.find(item => item.og_name === fields.u_og2);
                    if (level2) {
                        fields.u_og2_id = level2.og_id;
                        var level3 = level2.children.find(item => item.og_name === fields.u_og3);
                        if (level3) {
                            fields.u_og3_id = level3.og_id;
                        }
                    }
                }
            }
            return fields;
        }
    }));

    const filterOption = (inputValue, option) => {
        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    };

    const renderField = (name, title, icon, list) => {
        return (
            <Form.Item name={name} label={<>{icon}{title}</>} rules={[{type: 'string', max: enumUserFieldsMaxLength[name]}]}>
                <AutoComplete className='as-ant-select' options={list} filterOption={filterOption} />
            </Form.Item>
        );
    };

    const handleCloseOrgSetting = (og_titles, og_map) => {
        setShowOrgSetting(false);
        if (og_titles && og_map) {
            props.onOrgChange(og_titles, og_map);
        }
    };

    return (
        <Row className="usersetting-org-fields">
            <Col md="4">
                {renderField('u_og1', ogTitle1 || intl.formatMessage({id: 'division', defaultMessage: 'Division'}), <SVGIcon.OrganizationLvl1 />, listOg1)}
            </Col>
            <Col md="4">
                {renderField('u_og2', ogTitle2 || intl.formatMessage({id: 'department', defaultMessage: 'Department'}), <SVGIcon.OrganizationLvl2 />, listOg2)}
            </Col>
            <Col md="4">
                {renderField('u_og3', ogTitle3 || intl.formatMessage({id: 'sector', defaultMessage: 'Sector'}), <SVGIcon.OrganizationLvl3 />, listOg3)}
                {
                    context.accountInfo.type !== EnumAccountType.user &&
                    <Tooltip placement='bottom' title={<FormattedMessage id="organization_management" defaultMessage="Organization Management" />}>
                        <button type="button" className='image-btn' style={{position: 'absolute', top: '28px', right: '-15px'}} onClick={() => setShowOrgSetting(true)}>
                            <SVGIcon.Edit />
                        </button>
                    </Tooltip>
                }
            </Col>

            <OrganizationModal show={showOrgSetting} og_titles={props.og_titles} og_map={props.og_map} onClose={handleCloseOrgSetting} getContainer={() => document.querySelector('.usersetting-org-fields')} />
        </Row>
    );
});
OrganizationFields.defaultProps = {
    og_titles: ['', '', ''],
    og_map: [],
    onOrgChange: function(og_titles, og_map) {}
};

const OrganizationTitles = forwardRef(({og_titles, ...props}, ref) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const validateMessages = {
        string: {
            /* eslint-disable no-template-curly-in-string */
            max: intl.formatMessage({id: 'maximum_field_length'}, {'0': '${max}'})
        }
    };

    useEffect(() => {
        if (Array.isArray(og_titles) && og_titles.length === 3) {
            form.setFieldsValue({
                level_1: og_titles[0],
                level_2: og_titles[1],
                level_3: og_titles[2]
            });
        }
    }, [og_titles, form]);

    const renderField = (msgKey, level, icon) => {
        return (
            <Col md="4">
                <FormattedMessage id={msgKey}>
                {
                    (msg) => (
                        <Form.Item name={`level_${level}`} rules={[{type: 'string', max: 32}]}
                            label={<>{icon}<FormattedMessage id="organization_level_format" defaultMessage="Level {0}" values={{0: `${level}`}} /></>}
                        >
                            <Input placeholder={msg} allowClear={true} />
                        </Form.Item>
                    )
                }
                </FormattedMessage>
            </Col>
        );
    };

    useImperativeHandle(ref, () => ({
        validateFields(callback) {
            callback = callback || function() {};

            form.validateFields()
            .then((values) => {
                callback(true, [values.level_1, values.level_2, values.level_3]);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                if (Array.isArray(info.errorFields) && info.errorFields.length > 0) {
                    var field = form.getFieldInstance(info.errorFields[0].name[0]);
                    if (field) field.focus();
                }
                callback(false);
            });
        }
    }));

    return (
        <Card className='organization-title-card'>
            <Card.Header>
                <FormattedMessage id="organization_title" defaultMessage="Organization Title" />
            </Card.Header>
            <Card.Body>
                <Form form={form} className='organization-title-form' layout="vertical" autoComplete="off" validateMessages={validateMessages}>
                    <Row>
                        {renderField('division', 1, <SVGIcon.OrganizationLvl1 />)}
                        {renderField('department', 2, <SVGIcon.OrganizationLvl2 />)}
                        {renderField('sector', 3, <SVGIcon.OrganizationLvl3 />)}
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
});
OrganizationTitles.defaultProps = {
    og_titles: ['', '', ''],
};

function OrganizationModal(props) {
    const intl = useIntl();

    const [ogMap, setOgMap] = useState([]);

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [deleteNodes, setDeleteNodes] = useState([]);
    const [treeHeight, setTreeHeight] = useState(0);

    const [dragingNode, setDragingNode] = useState({node: null, deep: 0});
    const [dropNode, setDropNode] = useState(null);
    const [allowDrop, setAllowDrop] = useState(false);

    const [popperTarget, setPopperTarget] = useState(null);

    const [loading, setLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [nameField, setNameField] = useState({action: 'add', show: false, value: ''});

    const orgTitleFields = useRef();
    const orgTreeRef = useRef(null);
    const orgTreeContainerRef = useRef(null);

    const context = useContext(AppContext);
    
    useEffect(() => {
        if (props.show) {
            var rootNode = {
                og_id: 0,
                og_level: -1,
                og_parent: -1,
                og_name: intl.formatMessage({id: 'organization_root'})
            };
            if (Array.isArray(props.og_map)) {
                var og_map = JSON.parse(JSON.stringify(props.og_map));
                setOgMap([{
                    ...rootNode,
                    children: og_map
                }]);
            }
            setSelectedKeys([0]);
            setSelectedNode(rootNode);
            setAlertMsg('');
            setDeleteNodes([]);
            setTreeHeight(document.body.clientHeight - 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        if (Array.isArray(props.og_map)) {
            var rootNode = {
                og_id: 0,
                og_level: -1,
                og_parent: -1,
                og_name: intl.formatMessage({id: 'organization_root'})
            };
            setOgMap([{
                ...rootNode,
                children: JSON.parse(JSON.stringify(props.og_map))
            }]);
            var _expandedKeys = [];
            props.og_map.forEach(level1 => {
                if (Array.isArray(level1.children) && level1.children.length > 0) {
                    _expandedKeys.push(level1.og_id);
                    level1.children.forEach(level2 => {
                        if (Array.isArray(level2.children) && level2.children.length > 0) {
                            _expandedKeys.push(level2.og_id);
                        }
                    });
                }
            });
            setExpandedKeys([0, ..._expandedKeys]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.og_map]);

    const findNode = (tree, og_id) => {
        var node;
        tree.every(item => {
            if (item.og_id === og_id) {
                node = item;
            } else if (Array.isArray(item.children) && item.children.length > 0) {
                node = findNode(item.children, og_id);
            }
            if (node) return false;
            return true;
        });
        return node;
    };

    const nodeDeepCount = children => {
        if (Array.isArray(children) && children.length > 0) {
            var max = 0;
            children.forEach(item => {
                max = Math.max(max, nodeDeepCount(item.children));
            });
            return 1 + max;
        } else {
            return 0;
        }
    };

    const getRandomId = () => {
        return Math.round(Math.random(5000) * 1000000) + 1000000;
    };

    const handleAdd = () => {
        setNameField({
            action: 'add', show: true, value: ''
        });
    };

    const handleRename = () => {
        setNameField({
            action: 'rename', show: true, value: selectedNode.og_name
        });
    };

    const handleDelete = () => {
        if (!selectedNode || selectedNode.og_id === 0) return;
        var _ogMap = [...ogMap], siblings;

        var parentNode = findNode(_ogMap, selectedNode.og_parent_id);
        if (parentNode) {
            siblings = parentNode.children;
        } else {
            return;
        }

        var index = siblings.findIndex(item => item.og_id === selectedNode.og_id);
        if (index > -1) {
            var deleteNode = siblings.splice(index, 1)[0];
            if (!deleteNode.is_add) {
                deleteNode.action_type = EnumActionType.Delete;
                deleteNode.children = [];
                setDeleteNodes(deleteNodes.concat(deleteNode));
            }
            setOgMap(_ogMap);
            setSelectedKeys([]);
            setSelectedNode(null);
        }
    };

    const handleCloseNameField = (isSave, action, value) => {
        if (isSave) {
            var _ogMap = [...ogMap], siblings, existNode, parentNode, node;
            if (action === 'add') {
                if (selectedNode) {
                    parentNode = findNode(_ogMap, selectedNode.og_id);
                    if (parentNode) {
                        siblings = parentNode.children;
                    } else {
                        return;
                    }
                } else {
                    siblings = _ogMap;
                }

                existNode = siblings.find(item => item.og_name === value);
                if (!existNode) {
                    node = {
                        action_type: EnumActionType.Add,
                        og_id: getRandomId(),
                        og_name: value,
                        og_level: selectedNode ? (selectedNode.og_level + 1) : 0,
                        og_parent_id: selectedNode ? selectedNode.og_id : 0,
                        is_add: true,
                        children: []
                    };
                    siblings.push(node);
                    setSelectedKeys([node.og_id]);
                    setSelectedNode(node);
                    if (parentNode && !expandedKeys.includes(parentNode.og_id)) {
                        setExpandedKeys(expandedKeys.concat(parentNode.og_id));
                    }
                }
            } else if (action === 'rename' && selectedNode) {
                if (selectedNode.og_level === 0) {
                    siblings = _ogMap;
                } else {
                    parentNode = findNode(_ogMap, selectedNode.og_parent_id);
                    if (parentNode) {
                        siblings = parentNode.children;
                    } else {
                        return;
                    }
                }
                existNode = siblings.find(item => item.og_name === value && item.og_id !== selectedNode.og_id);
                if (!existNode) {
                    node = findNode(_ogMap, selectedNode.og_id);
                    node.og_name = value;
                    node.action_type = EnumActionType.Edit;
                    setSelectedKeys([node.og_id]);
                    setSelectedNode(node);
                }
            }

            if (existNode) {
                setAlertMsg(format(intl.formatMessage({id: 'organization_existed', defaultMessage: "''{0}'' existed."}), existNode.og_name));
            } else {
                setOgMap(_ogMap);
                setTimeout(() => {
                    if (node && orgTreeRef.current) {
                        orgTreeRef.current.scrollTo({key: node.og_id});
                    }
                }, 200);
            }
        }
        setNameField({
            action: 'add', show: false, value: ''
        });
    };

    const handleNodeSelect = (selectedKeys, e) => {
        setSelectedKeys(selectedKeys);
        setSelectedNode(e.selected ? e.selectedNodes[0] : null);
    };

    const handleTitleRender = (nodeData) => {
        return <span className={'organization-tree-title' + (dropNode && nodeData.og_id === dropNode.og_id ? ' drop-node' : '')}>
            {
                nodeData.og_level === 0 ? <SVGIcon.OrganizationLvl1 /> :
                nodeData.og_level === 1 ? <SVGIcon.OrganizationLvl2 /> :
                nodeData.og_level === 2 ? <SVGIcon.OrganizationLvl3 /> : null
            }
            {
                nodeData.og_name ? nodeData.og_name :
                <>--- <FormattedMessage id="empty" defaultMessage="Empty" /> ---</>
            }
        </span>
    };

    const handleDragStart = ({event, node}) => {
        event.dataTransfer.dropEffect = 'none';
        event.dataTransfer.effectAllowed = node.og_level === -1 ? 'none' : 'move';
        setAllowDrop(false);
        setDropNode(null);
        setSelectedKeys([node.og_id]);
        setSelectedNode(node);
        setPopperTarget(null);
        var _node = findNode(ogMap, node.og_id);
        if (_node) {
            setDragingNode({
                node: _node,
                deep: 1 + nodeDeepCount(_node.children)
            });
        }
    };

    const handleDragEnd = ({event, node}) => {
        setAllowDrop(false);
        setDropNode(null);
        setDragingNode({
            node: null,
            deep: 0
        });
    };
    
    const handleDragEnter = ({event, node}) => {
        event.preventDefault();
    };

    const handleDragOver = ({event, node}) => {
        event.preventDefault();
        setDropNode(null);
        if (!dragingNode || !dragingNode.node || dragingNode.node.og_level === -1) {
            setAllowDrop(false);
            event.dataTransfer.dropEffect = 'none';
            event.dataTransfer.effectAllowed = 'none';
            return;
        }

        if (orgTreeRef.current) {
            orgTreeRef.current.scrollTo({key: node.og_id, align: 'auto', offset: 60});
        }

        if (node.og_id === dragingNode.node.og_id || node.og_id === dragingNode.node.og_parent_id || node.og_level === 2) {
            setAllowDrop(false);
            event.dataTransfer.dropEffect = 'none';
            event.dataTransfer.effectAllowed = 'none';
            return;
        } else {
            var _node = findNode(ogMap, node.og_id);
            if (_node) {
                if (node.og_level + dragingNode.deep > 2) {
                    setAllowDrop(false);
                    event.dataTransfer.dropEffect = 'none';
                    event.dataTransfer.effectAllowed = 'none';
                    return;
                }
            }
        }

        setAllowDrop(true);
        setDropNode(node);
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'move';
    };

    const handleDragLeave = ({event, node}) => {
        event.preventDefault();
        setDropNode(null);
        setAllowDrop(false);
        event.dataTransfer.dropEffect = 'none';
    };

    const handleDrop = ({event, node, dragNode}) => {
        setAllowDrop(false);
        if (dropNode) {
            var _ogMap = [...ogMap], siblings;

            var parentNode = findNode(_ogMap, dragNode.og_parent_id);
            if (parentNode) {
                siblings = parentNode.children;
            } else {
                return;
            }
            
            // delete drag node
            var index = siblings.findIndex(item => item.og_id === dragNode.og_id),
                _dragNode;
            if (index > -1) {
                _dragNode = siblings.splice(index, 1)[0];
                if (!_dragNode.is_add) {
                    setDeleteNodes(deleteNodes.concat({
                        ..._dragNode,
                        action_type: EnumActionType.Delete,
                        children: []
                    }));
                }
            } else {
                return;
            }

            // append drag node
            parentNode = findNode(_ogMap, dropNode.og_id);
            if (!parentNode) return;
            var appendNode = {
                action_type: EnumActionType.Add,
                og_id: getRandomId(),
                og_name: _dragNode.og_name,
                og_level: parentNode.og_level + 1,
                og_parent_id: parentNode.og_id,
                is_add: true,
                children: []
            };
            _dragNode.children.forEach(item => {
                appendNode.children.push({
                    action_type: EnumActionType.Add,
                    og_id: getRandomId(),
                    og_name: item.og_name,
                    og_level: appendNode.og_level + 1,
                    og_parent_id: appendNode.og_id,
                    is_add: true,
                    children: []
                });
            });
            parentNode.children.push(appendNode);

            var _expandedKeys = [];
            if (!expandedKeys.includes(parentNode.og_id)) {
                _expandedKeys.push(parentNode.og_id);
            }
            if (appendNode.children.length > 0) {
                _expandedKeys.push(appendNode.og_id);
            };

            setOgMap(_ogMap);
            setSelectedKeys([appendNode.og_id]);
            setSelectedNode(appendNode);
            setDropNode(null);
            setExpandedKeys(expandedKeys.concat(_expandedKeys));
            setTimeout(() => {
                if (orgTreeRef.current) {
                    orgTreeRef.current.scrollTo({key: appendNode.og_id});
                }
            }, 200);
        }
    };

    const handleRightClick = ({event, node}) => {
        setSelectedKeys([node.og_id]);
        setSelectedNode(node);
        setPopperTarget(event.target.closest('.organization-tree-title') || event.target);
    };

    const handleDropdownSelect = ({key}) => {
        setPopperTarget(null);
        switch (key) {
            case 'add':
                handleAdd(); break;
            case 'rename':
                handleRename(); break;
            case 'delete':
                handleDelete(); break;
            default:
                break;
        }
    };

    const handleOk = () => {
        orgTitleFields.current.validateFields((valid, og_titles) => {
            if (!valid) return;

            var _ogMap = [...ogMap], saveMap = [], audit_memo = {og_list: []};
            _ogMap[0]?.children.forEach(level1 => {
                var org2s = [];
                level1.children.forEach(level2 => {
                    var org3s = [];
                    level2.children.forEach(level3 => {
                        if (level3.is_add) {
                            level3.og_id = -1;
                            level3.og_parent_id = level2.is_add ? -1 : level2.og_id
                        }
                        if (level3.action_type !== EnumActionType.None) {
                            org3s.push(level3);
                            audit_memo.og_list.push({
                                action: intl.formatMessage({id: level3.is_add ? 'add' : 'edit'}),
                                og_name: `${level1.og_name} - ${level2.og_name} - ${level3.og_name}`
                            });
                        }
                    });

                    if (level2.is_add) {
                        level2.og_id = -1;
                        level2.og_parent_id = level1.is_add ? -1 : level1.og_id
                    }
                    if (level2.action_type !== EnumActionType.None || org3s.length > 0) {
                        org2s.push({
                            ...level2,
                            children: org3s
                        });
                        if (level2.action_type !== EnumActionType.None) {
                            audit_memo.og_list.push({
                                action: intl.formatMessage({id: level2.is_add ? 'add' : 'edit'}),
                                og_name: `${level1.og_name} - ${level2.og_name}`
                            });
                        }
                    }
                });

                if (level1.is_add) {
                    level1.og_id = -1;
                }
                if (level1.action_type !== EnumActionType.None || org2s.length > 0) {
                    saveMap.push({
                        ...level1,
                        children: org2s
                    });
                    if (level1.action_type !== EnumActionType.None) {
                        audit_memo.og_list.push({
                            action: intl.formatMessage({id: level1.is_add ? 'add' : 'edit'}),
                            og_name: `${level1.og_name}`
                        });
                    }
                }
            });

            deleteNodes.forEach(node => {
                audit_memo.og_list.push({
                    action: intl.formatMessage({id: 'delete'}),
                    og_name: `${node.og_name}`
                });
            });
            saveMap = deleteNodes.concat(saveMap);

            var params = {
                api_cmd: EnumASCloudAPIType.OGANIZATION,
                action_type: EnumActionType.Edit,
                og_titles,
                og_list: saveMap,
                audit_memo
            };

            setSelectedKeys([]);
            setSelectedNode(null);
            setLoading(true);

            context.ajaxASCloud(Constants.urls.access, params, (resp) => {
                if (!resp.errcode) {
                    context.getOrganizationMap(true, (success, og_map, og_titles) => {
                        setLoading(false);
                        if (success) {
                            props.onClose(og_titles, og_map);
                        } else {
                            setAlertMsg(og_map);
                        }
                    });
                } else {
                    setAlertMsg(resp.errmsg);
                    setLoading(false);
                }
            });
        });
    };

    const getContainer = () => {
        return document.querySelector('.organization-tree');
    };

    return (
        <Modal centered width={800} open={props.show} onCancel={() => props.onClose()} onOk={handleOk} getContainer={props.getContainer}
            title={<FormattedMessage id="organization_management" defaultMessage="Organization Management" />}
            okText={<FormattedMessage id="ok" defaultMessage="OK" />}
            cancelText={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
        >
            <OrganizationTitles ref={orgTitleFields} og_titles={props.og_titles} />
            <br/>
            
            <Card className='organization-tree'>
                <Card.Header className='organization-tree-header'>
                    <span><FormattedMessage id="organization" defaultMessage="Organization" /></span>
                    <div className='organization-toolbar'>
                        <Button size='sm' onClick={handleAdd} disabled={!selectedNode || selectedNode.og_level === 2}><SVGIcon.Add /><FormattedMessage id="add" defaultMessage="Add"></FormattedMessage></Button>
                        <Button size='sm' onClick={handleDelete} disabled={!selectedNode || selectedNode.og_level === -1}><SVGIcon.Delete /><FormattedMessage id="delete" defaultMessage="Delete"></FormattedMessage></Button>
                        <Button size='sm' onClick={handleRename} disabled={!selectedNode || selectedNode.og_level === -1}><SVGIcon.Rename /><FormattedMessage id="rename" defaultMessage="Rename"></FormattedMessage></Button>
                    </div>
                </Card.Header>
                <Card.Body ref={orgTreeContainerRef} className='scroll-box' onContextMenu={e => e.preventDefault()}>
                    <Tree ref={orgTreeRef} blockNode fieldNames={{title: 'og_name', key: 'og_id'}} defaultExpandAll={true} 
                        className={`${allowDrop ? 'allow-drop' : ''}`} switcherIcon={getTreeSwitcherIcon}
                        height={treeHeight} style={{'--org-tree-height' : `${treeHeight}px`}}
                        treeData={ogMap} selectedKeys={selectedKeys} expandedKeys={expandedKeys}
                        draggable={{icon: false, nodeDraggable: (node) => {return !!node;}}}
                        onSelect={handleNodeSelect} 
                        onExpand={expandedKeys => setExpandedKeys(expandedKeys)}
                        titleRender={handleTitleRender}
                        onDragStart={handleDragStart} onDragEnd={handleDragEnd} 
                        onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} 
                        onDrop={handleDrop}
                        onRightClick={handleRightClick}
                    />

                    <TargetDropdown
                        target={popperTarget}
                        onClose={() => setPopperTarget(null)}
                        disabled={!selectedNode}
                        menu={{
                            items: [{
                                key: 'add', label: <FormattedMessage id="add" defaultMessage="Add" />, disabled: selectedNode?.og_level === 2
                            }, {
                                key: 'rename', label: <FormattedMessage id="rename" defaultMessage="Rename" />, disabled: selectedNode?.og_level === -1
                            }, {
                                key: 'delete', label: <FormattedMessage id="delete" defaultMessage="Delete" />, disabled: selectedNode?.og_level === -1
                            }],
                            onClick: handleDropdownSelect
                        }}
                        getPopupContainer={() => orgTreeContainerRef.current}
                    />
                    {/* <Overlay container={orgTreeContainerRef} placement='bottom' show={!!popperTarget} target={popperTarget} rootClose={true} onHide={() => setPopperTarget(null)}>
                        <Popover>
                            <Popover.Body className='organization-tree-dropdown'>
                                <Dropdown onSelect={handleDropdownSelect}>
                                    <Dropdown.Menu show={true} bsPrefix=' '>
                                        <Dropdown.Item eventKey='add' disabled={!selectedNode || selectedNode.og_level === 2}><FormattedMessage id="add" defaultMessage="Add"></FormattedMessage></Dropdown.Item>
                                        <Dropdown.Item eventKey='rename' disabled={!selectedNode || selectedNode.og_level === -1}><FormattedMessage id="rename" defaultMessage="Rename"></FormattedMessage></Dropdown.Item>
                                        <Dropdown.Item eventKey='delete' disabled={!selectedNode || selectedNode.og_level === -1}><FormattedMessage id="delete" defaultMessage="Delete"></FormattedMessage></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Popover.Body>
                        </Popover>
                    </Overlay> */}
                </Card.Body>
            </Card>

            <NameField {...nameField} getContainer={getContainer} onClose={handleCloseNameField} />

            <LoadingMask show={loading} text='' />
            <ShowAlertMessage title={<FormattedMessage id="organization_management" defaultMessage="Organization Management" />} type='error' getContainer={getContainer} 
                show={!!alertMsg} msg={alertMsg} onClose={() => setAlertMsg('')} />
        </Modal>
    );
}
OrganizationModal.defaultProps = {
    show: false,
    og_titles: ['', '', ''],
    og_map: [],
    getContainer: null,
    onClose: function(og_titles, og_map) {}
};

function NameField(props) {
    const maxLength = 32;
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (props.show) {
            setValue(props.value);
            if (inputRef) {
                inputRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    const handleOk = () => {
        if (value.trim() && value.toString().trim().length <= maxLength) {
            props.onClose(true, props.action, value.trim());
        }
    };

    const handleModalKeydown = e => {
        if (e.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <Modal centered width={450} open={props.show} onCancel={props.onClose} onOk={handleOk} getContainer={props.getContainer}
            title={props.action === 'add' ? <FormattedMessage id="add" defaultMessage="Add"></FormattedMessage> : <FormattedMessage id="rename" defaultMessage="Rename"></FormattedMessage>}
            okText={<FormattedMessage id="ok" defaultMessage="OK" />}
            okButtonProps={{disabled: !value || value.toString().trim().length > maxLength}}
            cancelText={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
        >
            <div onKeyDown={handleModalKeydown}>
                <Input ref={inputRef} allowClear={true} value={value} onChange={e => setValue(e.target.value)} />
            </div>
        </Modal>
    );
}
NameField.defaultProps = {
    action: 'add',
    show: false,
    value: '',
    getContainer: null,
    onClose: function(isSave, action, value) {}
};

export function CombineOrganization({u_og1, u_og2, u_og3, u_og1_id, u_og2_id, u_og3_id,...props}) {
    const { getOrganizationMap, organizationMap } = useContext(AppContext);
    const [orgNames, setOrgNames] = useState([]);

    useEffect(() => {
        if (u_og1 || u_og2 || u_og3) {
            var arr = [];
            if (u_og1) arr.push(u_og1);
            if (u_og2) arr.push(u_og2);
            if (u_og3) arr.push(u_og3);
            setOrgNames(arr);
        }
    }, [u_og1, u_og2, u_og3]);

    useEffect(() => {
        if (u_og1_id || u_og2_id || u_og3_id) {
            getOrganizationMap();
        }
    }, [u_og1_id, u_og2_id, u_og3_id, getOrganizationMap]);

    useEffect(() => {
        if ((u_og1_id || u_og2_id || u_og3_id) && organizationMap.length > 0) {
            var arr = [],
                tempOrg1, tempOrg2, tempOrg3;
            tempOrg1 = organizationMap.find(item => item.og_id === u_og1_id);
            if (tempOrg1) {
                arr.push(tempOrg1.og_name);

                tempOrg2 = tempOrg1.children.find(item => item.og_id === u_og2_id);
                if (tempOrg2) {
                    arr.push(tempOrg2.og_name);

                    tempOrg3 = tempOrg2.children.find(item => item.og_id === u_og3_id);
                    if (tempOrg3) arr.push(tempOrg3.og_name);
                }
            }

            setOrgNames(arr);
        }
    }, [organizationMap, u_og1_id, u_og2_id, u_og3_id]);

    return orgNames.join(' - ');
}