/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

import React, { useContext, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from "react";

import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { EnumWebSocketCmd } from '../ASUtils/ASConfig';
import { AppContext, Constants } from '../../Utils';

export const VMSContext = React.createContext({locale: {}});

export const useVMSGraphql = () => {
    const { accountInfo } = useContext(AppContext);
    const { locale } = useContext(VMSContext);

    const getErrorMessage = useCallback((message) => {
        var errmsg;
        if (message && locale.errorMessages) {
            errmsg = locale.errorMessages[message];
        }
        return errmsg;
    }, [locale]);

    const graphqlLink = createHttpLink({
        uri: `${Constants.gvCloudUrl}graphql`
    });
    
    const QUERY_EVENT_MAPPING = gql`
        query eventMappingQuery {
            query_event_mapping {
                event_id
                event_type_id
            }
        }
    `;

    const QUERY_SINGLE_EVNET = gql`
        query singleEventQuery ($id: Int!) {
            query_single_event(id: $id) {
                id
                device_name
                event_type
                event_id
                event_time
                host_name
                update_user_id
                relay_host_id
                relay_live_path
                relay_rpb_path
                cam
                host_code
                contact_name
                email
                phone
                address
                overview_link
                snapshot_link
                show_playback
                attachment_token
                ex_info
                external_settings
                external_ip
                external_port
                external_vlsvr_port
                external_streaming_port
                host {
                    type
                }
            }
        }
    `;

    // const QUERY_REGION_LIST = gql`
    //     query regionsQuery {
    //         query_region_list {
    //             id
    //             name
    //             managers {
    //                 user_id
    //             }
    //         }
    //     }
    // `;

    const QUERY_REGION_HOST_LIST = gql`
        query regionHostsQuery {
            query_region_host_list {
                id
                name
                default
                managers {
                    user_id
                }
            }
        }
    `;

    const QUERY_ACCOUNT_LIST = gql`
        query usersQuery {
            query_user (show_root: true) {
                id
                email
                name
                master
                profile_picture
            }
        }
    `;

    const MUTATION_LOGIN = gql`
        mutation LoginMutation($accountId: Int!, $email: String!, $password: String!, $tz: String) {
            login(
                account_id: $accountId
                email: $email
                password: $password
                tz_id: $tz
                dev: "web"
                skip_recaptcha: "0287978376"
            ) {
                id
                account_id
                name
                email
                master
                language
                token
            }
        }
    `;

    const MUTATION_TEMP_TOKEN = gql`
        mutation SetTempTokenMutation  {
            set_token
        }
    `;

    const MUTATION_CREATE_REGION = gql`
        mutation CreateRegionMutation($name: String!) {
            create_region(name: $name) {
                id
                name
                default
            }
        }
    `;

    const MUTATION_UPDATE_REGION = gql`
        mutation UpdateRegionMutation($id: Int!, $name: String!) {
            update_region(id: $id, name: $name)
        }
    `;

    const MUTATION_DELETE_REGION = gql`
        mutation DeleteRegionMutation($id: Int!) {
            delete_region(id: $id)
        }
    `;

    const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: accountInfo.gv_user_token
          }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(graphqlLink),
        cache: new InMemoryCache()
    });

    const queryEventMapping = (callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_EVENT_MAPPING
        })
        .then(data => {
            if (Array.isArray(data?.data?.query_event_mapping)) {
                callback(true, data?.data?.query_event_mapping);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const querySingleEvent = (id, callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_SINGLE_EVNET,
            variables: {id}
        })
        .then(data => {
            if (typeof(data?.data?.query_single_event) === 'object') {
                callback(true, data?.data?.query_single_event);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const queryRegionList = (callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_REGION_HOST_LIST
        })
        .then(data => {
            if (Array.isArray(data?.data?.query_region_host_list)) {
                var resp = {
                    data: data.data.query_region_host_list.map(item => {
                        return {
                            rg_id: item.id,
                            rg_name: item.name,
                            default: item.default,
                            rg_admins: item.managers.map(subitem => subitem.user_id)
                        };
                    })
                };
                callback(true, resp);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const queryAccountList = (callback) => {
        callback = callback || function() {};

        client.query({
            query: QUERY_ACCOUNT_LIST
        })
        .then(data => {
            if (Array.isArray(data?.data?.query_user)) {
                var resp = {
                    data: data.data.query_user
                };
                callback(true, resp);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const mutateLogin = (accountId, email, password, callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_LOGIN,
            variables: {
                accountId, email, password,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        })
        .then(data => {
            if (typeof(data?.data?.login) === 'object') {
                callback(true, data?.data?.login);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const mutationTempToken = (callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_TEMP_TOKEN
        })
        .then(data => {
            if (data?.data?.set_token) {
                callback(true, data?.data?.set_token);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const createRegion = (rg_name, callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_CREATE_REGION,
            variables: {
                name: rg_name
            }
        })
        .then(data => {
            if (data?.data?.create_region) {
                callback(true, data.data.create_region.id);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const updateRegion = (rg_id, rg_name, callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_UPDATE_REGION,
            variables: {
                id: rg_id,
                name: rg_name
            }
        })
        .then(data => {
            if (data?.data?.update_region) {
                callback(true);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    const deleteRegion = (rg_id, callback) => {
        callback = callback || function() {};

        client.mutate({
            mutation: MUTATION_DELETE_REGION,
            variables: {
                id: rg_id
            }
        })
        .then(data => {
            if (data?.data?.delete_region) {
                callback(true);
            } else {
                callback(false);
            }
        })
        .catch((e) => {
            callback(false, getErrorMessage(e.message));
        });
    };

    return {
        queryEventMapping, querySingleEvent, queryRegionList, queryAccountList, mutateLogin, mutationTempToken,
        createRegion, updateRegion, deleteRegion
    };
};


class VMSWebSocket extends React.Component {
    _wsKeepAliveTime = (window.chrome ? 8.5 : 9.5) * 60 * 1000; // Websocket will be disconnected when no transmission for more than 10 minutes. (When web is invisible for more than 5 minutes, Chrome will start throttling, with a maximum delay of 1 minute.)
    _wsReconnectTime = 115 * 60 * 1000; // Websocket will be disconnected by AWS for every 2 hours.
    _wsMaxErrorCount = 20;
    _ws = null;
    _wsKeepAliveTimer = null;
    _wsErrorCount = 0;
    _wsReconnectTimer = null;
    _wsPositiveDisconnect = false;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        this._connectWS();
    }

    componentWillUnmount() {
        clearTimeout(this._wsKeepAliveTimer);
        clearTimeout(this._wsReconnectTimer);
        if (this._ws) {
            this._ws.onopen = null;
            this._ws.onmessage = null;
            this._ws.onclose = null;
            this._ws.onerror = null;
            this._ws.close(1000);
        }
    }

    debugMsg = (module, msg) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            const padZero = (val, length) => {
                return val.toString().padStart(length || 2, '0');
            };
            var now = new Date(),
                time = `${now.getFullYear()}-${padZero(now.getMonth()+1)}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}.${padZero(now.getMilliseconds(), 3)}`;
            console.log(`${time} [${module}]: ${msg}`);
        }
    };

    _debugMsgWS = (msg, send) => {
        if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
            var strSend = send ? 'Send' : (send === false ? 'Receive' : '');
            var strCmd = '';

            try {
                var data = JSON.parse(msg);
                if (data.type) {
                    strCmd = data.type;
                }
            } catch {}

            this.debugMsg('VMSWebSocket', `${strSend}: ${strCmd || ''}, ${msg}`);
        }
    };

    _connectWS = (reconnect) => {
        var _self = this,
            _ws;

        const clearWebsocket = (ws, code) => {
            ws.onopen = null;
            ws.onmessage = null;
            ws.onclose = null;
            ws.onerror = null;
            ws.close(code);
            ws = null;
        };

        if (!reconnect && _self._ws) {
            clearWebsocket(_self._ws);
        }

        _self._wsPositiveDisconnect = true;
        _ws = new WebSocket(`${process.env.REACT_APP_URL_GVCLOUD_GLOBAL_WEBSOCKET}?x-token=${_self.props.gv_user_token}&x-token-type=user`);
        
        _ws.onopen = function(e) {
            _self._debugMsgWS('Open');
            _self._wsErrorCount = 0;

            if (reconnect && _self._ws) {
                clearWebsocket(_self._ws, 1000);
            }
            _self._ws = _ws;
            _self._wsPositiveDisconnect = false;

            _self.sendWS({
                action: 'SUBSCRIBE',
                authorization: _self.props.gv_user_token
            });
        };

        _ws.onmessage = function(e) {
            _self._debugMsgWS(e.data, false);
            _self._keepWSAlive();
            var data = {};
            try {
                data = JSON.parse(e.data);
            } catch {}

            _self.props.onNotify(data);
        };

        _ws.onclose = function(e) {
            if (!_self._wsPositiveDisconnect) { // if not possive to discconet, then reload page
                if (e.code === 1006) {
                    _self.debugMsg('Reload', `websocket closed abnormally. code: ${e.code}`);
                    var notifyData = {cmd_id: EnumWebSocketCmd.ABNORMAL_CLOSE_WEBSOCKET};
                    _self.props.onNotify(notifyData);
                    return;
                }

                if (process.env.REACT_APP_TYPE === 'dev') {
                    _self.debugMsg('Reload', `websocket closed not possive, so Reload Page. code: ${e.code}`);
                    alert(`websocket closed not possive, so Reload Page. code: ${e.code}`);
                }
                _self._wsReloadPage = true;
                window.location.reload();
                return;
            }

            _self._ws = null;
            _self._debugMsgWS(`Close, wasClean: ${e.wasClean}, code: ${e.code}, reason: ${e.reason}, error count: ${_self._wsErrorCount}`);

            if (_self._wsErrorCount < _self._wsMaxErrorCount) {
                setTimeout(() => {
                    _self._connectWS();
                }, _self._wsErrorCount * 1000);
            } else {
                _self._debugMsgWS(`ReConnect Websocket Fail.`);
                // window.location = `${gvCloudUrl}?redirect=/${enumServicePath[_self.props.serviceType]}&pathname=${window.location.pathname}`;
            }
        };

        _ws.onerror = function(e) {
            _self._ws = null;
            _self._debugMsgWS(`Error, error count: ${_self._wsErrorCount}, message: ${e.message}`);
            _self._wsErrorCount++;
        };

        _self._keepWSAlive();
        
        clearTimeout(_self._wsReconnectTimer);
        _self._wsReconnectTimer = setTimeout(() => {
            _self._connectWS(true);
        }, _self._wsReconnectTime);
    };

    _keepWSAlive = () => {
        clearTimeout(this._wsKeepAliveTimer);
        this._wsKeepAliveTimer = setTimeout(this.sendWS, this._wsKeepAliveTime, {action: 'PING'});
    };

    sendWS = (params) => {
        if (this._ws) {
            var str = JSON.stringify(params);
            this._ws.send(str);
            this._debugMsgWS(str, true);
            this._keepWSAlive();
        }
    };

    render() {
        return null;
    }
}
VMSWebSocket.defaultProps = {
    gv_user_token: '',
    onNotify: function(data) {}
};

const VMSGraphql = forwardRef(({onNotify, ...props}, ref) => {
    const { accountInfo } = useContext(AppContext);
    const vmsGraphql = useVMSGraphql();
    const [locale, setLocale] = useState({});
    const fetchingRef = useRef(false);

    useEffect(() => {
        if (fetchingRef.current) return;

        async function fetchData(lang) {
            fetchingRef.current = true;
            const doFetch = async (_lang) => {
                const resp = await fetch(`${Constants.thirdPartyUrls.resouce}lang${process.env.REACT_APP_TYPE === 'dev' ? '/dev' : ''}/${_lang}.json?${new Date().getTime()}`);
                const data = await resp.json();
                setLocale(data);
                fetchingRef.current = false;
            };
            try {
                await doFetch(lang);
            } catch (e) {
                console.log(e);
                await doFetch('en');
            }
		}
        fetchData(accountInfo.lang);
    }, [accountInfo.lang]);

    useImperativeHandle(ref, () => ({
        ...vmsGraphql
    }));
    // wait for vms support ws player
    return (
        <VMSContext.Provider value={{locale}}>
            {
                !!accountInfo.gv_user_token &&
                <VMSWebSocket gv_user_token={accountInfo.gv_user_token} onNotify={onNotify} />
            }
            {props.children}
        </VMSContext.Provider>
    );
    // return null;
});
VMSGraphql.defaultProps = {
    onNotify: function(data) {}
};
export default VMSGraphql;


export const EventString = ({event_type, event_id}) => {
    const { locale } = useContext(VMSContext);

    const eventTypeString = locale?.events?.eventTypes?.[event_type];
    const eventIdString = locale?.events?.eventIds?.[event_id];
    if (event_type && event_id) {
        return `${eventTypeString} / ${eventIdString}`;
    } else if (event_type) {
        return eventTypeString;
    } else if (event_id) {
        return eventIdString;
    } else {
        return '';
    }
};