import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from "react-intl";

import { Card, ProgressBar } from 'react-bootstrap';
import { Modal, Select, Button, Spin, Form, Input, Tooltip, Slider } from 'antd';
import { FileDrop } from 'react-file-drop';
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { LoadingOutlined, RotateLeftOutlined, RotateRightOutlined, UploadOutlined } from '@ant-design/icons';

import { Cropper, CircleStencil } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

import { EnumASCloudAPIType, EnumActionType, EnumErrorMessage } from '../ASUtils/ASConfig.js';
import { LoadingMask, ShowAlertMessage, ClearASImageUserCache } from '../../modules/Common/Common.js';
import { Constants, AppContext, ArrayToggle } from '../../Utils';
import SVGIcon from '../../icons.js';
import './ImportPhoto.css';


export const maxPhotoSize = 600;
export const maxSmallPhotoSize = 120;
const storageKey = 'importPhotoPreviewSize';
const EnumPhotoStatus = {
    None: 0,
    Valid: 1,
    Invalid: 2,
    Uploading: 3,
    Success: 4,
    Fail: 5
};
const defaultImportState = {
    start_time: new Date().getTime(),
    total: 0,
    success: 0,
    fail: 0
};

const forEach = (arr, callback) => {
    callback = callback || function() {};
    for (var i = 0; i < arr.length; i++) {
        callback(arr[i], i);
    }
};

const getFileList = (dataTransfer) => {
    return new Promise((resolve, reject) => {
        if (!dataTransfer || !dataTransfer.items?.length) {
            reject('');
            return;
        };

        const getDirectoryEntries = (directoryEntry) => {
            return new Promise((resolve, reject) => {
                var dirReader = directoryEntry.createReader();
                dirReader.readEntries((entries) => resolve(entries));
            });
        };
    
        const readFiles = (entry, path) => {
            return new Promise((resolve, reject) => {
                path = path || '';
    
                if (entry.isFile) {      // Get file
                    entry.file(function(file) {
                        if (file.type.toString().includes('image/')) {
                            resolve([{file, filename: file.name, status: EnumPhotoStatus.None, u_user_value: removeExtension(file.name)}]);
                        } else {
                            resolve([]);
                        }
                    });
                } else if (entry.isDirectory) {      // Get folder contents
                    getDirectoryEntries(entry)
                    .then((entries) => {
                        var temps = [], count = 0;
                        entries.forEach(item => {
                            readFiles(item, path + item.name + "/")
                            .then(values => {
                                temps = temps.concat(values);
                                count++;
                                if (count === entries.length) {
                                    resolve(temps);
                                }
                            });
                        });
                    });
                }
            });
        };

        var temps = [], len = dataTransfer.items?.length, count = 0;
        forEach(dataTransfer.items, item => {
            var directoryEntry = item.webkitGetAsEntry();
            if (directoryEntry) {
                readFiles(directoryEntry)
                .then(value => {
                    temps = temps.concat(value);
                    count++;

                    if (count === len) {
                        resolve(temps);
                    }
                });
            } else {
                count++;
            }
        });
    });
};

export const getJpegBase64String = (imgSrc, callback) => {
    callback = callback || function(){};

    var getJpegBase64 = function(img, maxSize) {
        var newWidth = img.naturalWidth,
            newHeight = img.naturalHeight;

        if (newWidth > maxSize) {
            newHeight = maxSize * (newHeight / newWidth);
            newWidth = maxSize;
        }
        if (newHeight > maxSize) {
            newWidth = maxSize * (newWidth / newHeight);
            newHeight = maxSize;
        }
        newWidth = Math.round(newWidth);
        newHeight = Math.round(newHeight);

        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, newWidth, newHeight);
        const base64 = canvas.toDataURL('image/jpeg', 0.7);

        canvas.remove();
        canvas = null;
    
        return base64;
    };

    var img = new Image();
    img.onload = function() {
        var base64Image = getJpegBase64(this, maxPhotoSize),
            base64Image_s = getJpegBase64(this, maxSmallPhotoSize);

        callback(base64Image, base64Image_s);
    };
    img.src = imgSrc;
};

const getImageBase64String = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(){
            if (reader.result.includes('data:image')) {
                getJpegBase64String(reader.result, (u_photo, u_photo_s) => {
                    resolve({u_photo, u_photo_s});
                });
            } else {
                reject();
            }
        };
    });
};

const removeExtension = (filename) => {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
};

const PhotoItem = ({readOnly, active, filename, status, u_photo_s, u_user_value, u_name, errmsg, onClick, onRemove}) => {

    return (
        <div className={`import-photo-file-item ${active ? 'active' : ''}`} filename={filename} draggable={false} onClick={(e) => onClick(filename, e)}>
            <div className={`gv-user-avatar ${status === EnumPhotoStatus.None ? 'loading' : ''}`}>
            {
                status === EnumPhotoStatus.None ?
                <Spin />
                :
                u_photo_s?
                <img alt={u_user_value} src={u_photo_s} onLoad={e => e.target.classList.add('loaded')} draggable={false} />
                :
                null
            }
            </div>
            <div className='import-photo-file-item-fields'>
                <span>{u_user_value}</span>
                {
                    u_name && u_name !== u_user_value && <span className='user-name'>[{u_name}]</span>
                }
                {
                    errmsg && <span className='error-msg'>{errmsg}</span>
                }
            </div>
            
            {
                status !== EnumPhotoStatus.None && status !== EnumPhotoStatus.Valid &&
                <div className='import-photo-file-item-status'>
                {
                    status === EnumPhotoStatus.Uploading ?
                    <Spin size='small' /> :
                    status === EnumPhotoStatus.Success ?
                    <FaCheck className='success' /> : <FaExclamationTriangle className='fail' />
                }
                </div>
            }
            {
                !readOnly &&
                <div className='import-photo-file-item-buttons'>
                    <span onClick={(e) => onRemove(filename, e)}>
                        <SVGIcon.DeleteCircle />
                    </span>
                </div>
            }
        </div>
    );
};
PhotoItem.defaultProps = {
    readOnly: false,
    active: false,
    filename: '',
    status: EnumPhotoStatus.None,
    u_photo_s: null,
    u_user_value: '',

    // 
    u_name: '',
    errmsg: '',
    onClick: function(filename, e) {},
    onRemove: function(filename, e) {},
};

const ImportPhoto = ({onClose}) => {
    const batchUploadCount = 10;
    const intl = useIntl();
    const { ajaxASCloud } = useContext(AppContext);
    const [open, setOpen] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState({show: false, msg: '', type: '', onClose: null});
    const [imgSize, setImgSize] = useState(parseInt(window.localStorage.getItem(storageKey)) || 56);
    const [mappingKey, setMappingKey] = useState('u_name');
    const [files, setFiles] = useState([]);     // [{file, filename, status, u_user_value, u_photo, u_photo_s,   u_name, errmsg}]
    const [selecteds, setSelecteds] = useState([]); // [filename]
    const [editPhoto, setEditPhoto] = useState(null);   // {file, filename, u_user_value}
    const [uploading, setUploading] = useState(false);
    const [spendedSec, setSpendedSec] = useState(0);
    const [remainingSec, setRemainingSec] = useState(Infinity);
    const fileInputRef = useRef(null);
    const abortRef = useRef(false);
    const uploadStartTimeRef = useRef();
    const timeClockIntervalRef = useRef();
    const importState = useRef();

    const disableUpload = useMemo(() => {
        return files.some(item => item.status === EnumPhotoStatus.None);
    }, [files]);

    const progressLabel = useMemo(() => {
        if (!uploading) return '';

        const renderTimeString = sec => {
            var str = '-';
            if (isFinite(sec)) {
                if (sec > 0) {
                    str = `${Math.floor(sec / 3600).toString().padStart(2, '0')}:${Math.floor((sec % 3600) / 60).toString().padStart(2, '0')}:${Math.round(sec % 60).toString().padStart(2, '0')}`;
                } else {
                    str = intl.formatMessage({id: 'upload_almost_done'});
                }
            }
            return str;
        };

        return `${files.filter(item => item.status > EnumPhotoStatus.Valid).length} / ${files.length}, ` +
               `${intl.formatMessage({id: 'upload_spended_time'})}: ${renderTimeString(spendedSec)}, ` +
               `${intl.formatMessage({id: 'upload_remaining_time'})}: ${renderTimeString(remainingSec)}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploading, files, spendedSec, remainingSec]);
    
    const getContainer = () => {
        return editPhoto ? document.querySelector('.edit-photo-modal') : document.querySelector('.import-photo-modal');
    };

    const renderFiles = async (fileList) => {
        var _files = [...files], _selecteds = [];
        fileList.forEach(item => {
            if (_files.every(file => file.u_user_value !== item.u_user_value && file.filename !== item.filename)) {
                _files.push(item);
                _selecteds.push(item.filename);
            }
        });
        setFiles(_files);
        setSelecteds(_selecteds);
        setShowLoading(false);

        for (var i = 0; i < _files.length; i++) {
            if (!_files[i].file || _files[i].status !== EnumPhotoStatus.None) continue;
            
            try {
                const values = await getImageBase64String(_files[i].file);
                Object.assign(_files[i], {
                    ...values,
                    status: EnumPhotoStatus.Valid
                });
                // delete _files[i].file;
                setFiles([..._files]);

                const el = document.querySelector(`.import-photo-file-item[filename="${_files[i].filename}"]`);
                if (el) {
                    el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                }
            } catch (e) {
                _files[i].status = EnumPhotoStatus.Invalid;
                _files[i].errmsg = intl.formatMessage({id: 'invalid_data'});
                setFiles([..._files]);
            }
        }
    };

    const handleDrop = (_, e) => {
        if (disableUpload || uploading) return;

        setShowLoading(true);
        getFileList(e.dataTransfer).then(renderFiles);
    };

    const handleContainerClick = () => {
        if (files.length > 0) return;
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        setShowLoading(true);
        var fileList = [];
        forEach(event.target.files, (file) => {
            fileList.push({file, filename: file.name, status: EnumPhotoStatus.None, u_user_value: removeExtension(file.name)});
        });
        renderFiles(fileList);
        event.target.value = '';
    };

    const handlePhotoClick = (filename, e) => {
        if (e.shiftKey && selecteds.length > 0) {
            var idx = files.findIndex(item => item.filename === filename),
                lastIdx = files.findIndex(item => item.filename === selecteds[selecteds.length - 1]);
            if (idx > -1 && lastIdx > -1 && idx !== lastIdx) {
                setSelecteds(current => {
                    var arr = e.ctrlKey ? [...current] : [];
                    files.slice(Math.min(idx, lastIdx), Math.max(idx, lastIdx) + 1).forEach(file => {
                        if (!arr.includes(file.filename)) arr.push(file.filename);
                    });
                    return arr;
                });
            }
        } else if (e.ctrlKey) {
            setSelecteds(current => ArrayToggle([...current], filename));
        } else {
            setSelecteds([filename]);
        }
    };

    const handlePhotoEdit = () => {
        if (selecteds.length !== 1) return;
        const file = files.find(item => item.filename === selecteds[0]);
        if (file.u_photo) {
            setEditPhoto(files.find(item => item.filename === selecteds[0]))
        }
    };

    const handlePhotoRemove = (filenames, e) => {
        filenames = [].concat(filenames);
        e.stopPropagation();
        setFiles(current => {
            var _files = [...current];
            filenames.forEach(filename => {
                const index = _files.findIndex(item => item.filename === filename);
                if (index > -1) {
                    _files.splice(index, 1);
                }
            });
            return _files;
        });
        setSelecteds(current => {
            var _selecteds = [...current];
            filenames.forEach(filename => {
                const index = _selecteds.indexOf(filename);
                if (index > -1) {
                    _selecteds.splice(index, 1);
                }
            });
            return _selecteds;
        });
    };

    const handlePhotoChange = (photoData) => {
        if (files.some(item => item.filename !== photoData.filename && item.u_user_value === photoData.u_user_value)) {
            setAlertMsg({show: true, msg: <FormattedMessage id='file_name_duplicate' />, type: 'error', onClose: null});
        } else {
            setFiles(current => {
                var _files = [...current],
                    file = _files.find(item => item.filename === photoData.filename);
                if (file) {
                    Object.assign(file, {
                        ...photoData,
                        status: EnumPhotoStatus.Valid,
                        errmsg: '',
                        u_name: ''
                    });
                    return _files;
                }
                return current;
            }); 
            setEditPhoto(null);
        }
    };

    const handlePhotoError = (filename) => {
        setFiles(current => {
            var _files = [...current],
                file = _files.find(item => item.filename === filename);
            if (file) {
                Object.assign(file, {
                    status: EnumPhotoStatus.Invalid,
                    errmsg: intl.formatMessage({id: 'invalid_data'})
                });
                return _files;
            }
            return current;
        }); 
        setEditPhoto(null);
        setAlertMsg({show: true, msg: <FormattedMessage id='invalid_data' />, type: 'error', onClose: null});
    };

    const doUpload = (_files) => {
        var successCount = _files.filter(item => item.status === EnumPhotoStatus.Success).length,
            errorCount = _files.filter(item => item.status === EnumPhotoStatus.Fail).length;
        importState.current = {
            ...importState.current,
            success: successCount,
            fail: errorCount
        };

        var index = _files.findIndex(item => item.status === EnumPhotoStatus.Valid), i;
        if (index > -1 && !abortRef.current) {
            if (index === 0) {  // import start
                importState.current.start_time = new Date().getTime();
                importState.current.total = _files.length;
                // this.importStateRef.current.addAuditLog(EnumAuditLogMessage.ImportUserStarted, this._importState);
            }
            // this.importStateRef.current.update(this._importState);

            var params = {
                api_cmd: EnumASCloudAPIType.BATCH_USER_PHOTO,
                action_type: EnumActionType.Edit,
                u_user_key: mappingKey,
                is_notify: index + batchUploadCount >= _files.length ? 1 : 0,
                u_photoes: []
            };

            for (i = 0; (i < batchUploadCount && index + i < _files.length); i++) {
                params.u_photoes.push({
                    u_user_value: _files[index + i].u_user_value,
                    u_photo: _files[index + i].u_photo.replace('data:image/jpeg;base64,', ''),
                    u_photo_s: _files[index + i].u_photo_s.replace('data:image/jpeg;base64,', ''),
                });
            }
            for (i = 0; i < params.u_photoes.length; i++) {
                _files[index + i].status = EnumPhotoStatus.Uploading;
            }

            if (!params.u_photoes.length) return;

            setFiles(_files);
            ajaxASCloud(Constants.urls.access, params, (resp) => {
                
                if (!resp.errcode && resp.data && Array.isArray(resp.data) && resp.data.length === params.u_photoes.length) {
                    for (i = 0; i < params.u_photoes.length; i++) {
                        if (resp.data[i].u_errcode) {
                            _files[index + i].status = EnumPhotoStatus.Fail;
                            _files[index + i].errmsg = [intl.formatMessage({id: EnumErrorMessage[resp.data[i].u_errcode]}) || intl.formatMessage({id: 'err_msg_internal_error'})];
                        } else {
                            _files[index + i].status = EnumPhotoStatus.Success;
                            ClearASImageUserCache(resp.data[i].u_user_id);
                        }
                        _files[index + i].u_name = resp.data[i].u_name;
                    }
                }  else {
                    for (i = 0; i < params.u_photoes.length; i++) {
                        _files[index + i].status = EnumPhotoStatus.Fail;
                        _files[index + i].errmsg = [intl.formatMessage({id: EnumErrorMessage[resp.errcode]}) || resp.errmsg || intl.formatMessage({id: 'err_msg_internal_error'})];
                    }
                }

                setFiles(_files);
                var oneTime = (new Date() - uploadStartTimeRef.current) / _files.filter(item => item.status === EnumPhotoStatus.Fail || item.status === EnumPhotoStatus.Success).length;
                setRemainingSec(Math.round(_files.filter(item => item.status === EnumPhotoStatus.Valid).length * oneTime / 1000));

                setTimeout(() => {
                    doUpload(_files);
                }, 100);
            });

        } else {
            var completed = _files.length === successCount + errorCount,
                msg = intl.formatMessage({id: 'import_success_format'}, {0: successCount, 1: errorCount});

            setUploading(false);
            setAlertMsg({
                show: true,
                msg: <span><FormattedMessage id={completed ? 'import_success' : 'import_fail'} /><br/>{msg}</span>,
                type: errorCount > 0 || !completed ? 'warning' : 'success',
                onClose: errorCount > 0 || !completed ? null: (() => {
                    setAlertMsg({show: false, msg: '', type: '', onClose: null});
                    setOpen(false);
                    setTimeout(onClose, 300);
                })
            });
        }
    };

    const handleUpload = () => {
        setUploading(true);
        setSpendedSec(0);
        setRemainingSec(Infinity);
        importState.current = {...defaultImportState};
        abortRef.current = false;
        uploadStartTimeRef.current = new Date();
        timeClockIntervalRef.current = setInterval(() => {
            setSpendedSec(Math.round((new Date() - uploadStartTimeRef.current) / 1000));
            setRemainingSec(current => current - 1);
        }, 1000);
        doUpload([...files]);
    };

    return (
        <Modal centered width={1000} maskClosable={false} className='import-photo-modal' open={open} onCancel={() => {setOpen(false); setTimeout(onClose, 300);}}
            closable={!uploading} destroyOnClose={true}
            title={<FormattedMessage id='import_photo' />} okText={<FormattedMessage id='upload' />}
            footer={
                [].concat(
                    uploading ?
                    <div key='progress' className='upload-progress'>
                        <Spin size='small' indicator={<LoadingOutlined spin />} />
                        <div className='upload-progress-bar'>
                            <span>{progressLabel}</span>
                            <ProgressBar now={files.filter(item => item.status > EnumPhotoStatus.Valid).length / files.length * 100} />
                        </div>
                        <Button type="danger" size="small" onClick={() => abortRef.current = true}>
                            <FormattedMessage id="abort" defaultMessage="Abort" />
                        </Button>
                    </div>
                    : []
                ).concat(
                    <Button key='cancel' disabled={uploading} onClick={() => {setOpen(false); setTimeout(onClose, 300);}}>
                        <FormattedMessage id='cancel' />
                    </Button>
                ).concat(
                    <Button key='upload' type='primary' disabled={!files.length || disableUpload || uploading || files.some(item => item.status !== EnumPhotoStatus.Valid)} onClick={handleUpload}>
                        <FormattedMessage id='upload' />
                    </Button>
                )
            }
        >
            <Card className='import-photo-card'>
                <Card.Header>
                    <FormattedMessage id='total' />: {files.length}
                    <div className='space'></div>
                    {
                        !!files.length &&
                        <Slider style={{width: '80px'}} tooltip={{formatter: (value) => `${value}px`}} min={24} max={96} step={8} value={imgSize} 
                            onChange={(val) => {setImgSize(val); window.localStorage.setItem(storageKey, val);}}
                        />
                    }
                    <Button type='primary' size='small' icon={<SVGIcon.Add />} disabled={disableUpload || uploading} onClick={() => fileInputRef.current.click()}>
                        <FormattedMessage id='add' />
                    </Button>
                    <Button type='primary' size='small' icon={<SVGIcon.Edit />} disabled={disableUpload || uploading || selecteds.length !== 1} onClick={handlePhotoEdit}>
                        <FormattedMessage id='edit' />
                    </Button>
                    <Button type='primary' size='small' icon={<SVGIcon.Delete />} disabled={disableUpload || uploading || selecteds.length === 0} onClick={(e) => handlePhotoRemove(selecteds, e)}>
                        <FormattedMessage id='delete' />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <FileDrop className='import-photo-file-drop' targetClassName='import-photo-file-drop-target'
                        onDrop={handleDrop}
                    >
                        <div className='import-photo-file-list noselect scroll-box' 
                            style={{cursor: files.length === 0 ? 'pointer' : 'default', '--import-photo-size': `${imgSize}px`}} 
                            onClick={handleContainerClick}
                        >
                        {
                            files.map((item, index) => (
                                <PhotoItem key={item.filename} {...item} readOnly={disableUpload} active={selecteds.includes(item.filename)} onClick={handlePhotoClick} onRemove={handlePhotoRemove} />
                            ))
                        }
                        {
                            !files.length &&
                            <span className='import-photo-file-drop-tip'><FormattedMessage id='empty_import_photo' /></span>
                        }
                        </div>
                    </FileDrop>
                </Card.Body>
            </Card>
            

            <input ref={fileInputRef} type="file" multiple accept="image/*" style={{display: 'none'}} onChange={handleFileInputChange} />

            <div className='import-photo-field'>
                <label><FormattedMessage id='file_name_maps_to' />:</label>
                <Select className='as-ant-select' disabled={uploading} value={mappingKey} onChange={(value) => setMappingKey(value)}>
                    <Select.Option value='u_name'><FormattedMessage id='display_name' /></Select.Option>
                    <Select.Option value='u_id'><FormattedMessage id='id' /></Select.Option>
                    <Select.Option value='u_email'><FormattedMessage id='email' /></Select.Option>
                    {/* <Select.Option value='u_phone'><FormattedMessage id='phone' /></Select.Option> */}
                </Select>
            </div>

            <EditPhoto show={!!editPhoto} {...editPhoto} onSave={handlePhotoChange} onError={handlePhotoError} onClose={() => setEditPhoto(null)} />

            <LoadingMask show={showLoading} text='' />
            <ShowAlertMessage title={<FormattedMessage id="import_photo" />} {...alertMsg} backdrop='static' getContainer={getContainer}
                onClose={alertMsg.onClose || (() => {setAlertMsg({show: false, msg: '', type: '', onClose: null})})} 
            />
        </Modal>
    );
};
ImportPhoto.defaultProps = {
    onClose: function() {}
};

export default ImportPhoto;

export const EditPhoto = ({show, file, imgSrc, filename, u_user_value, showUpload, onSave, onError, onClose}) => {
    const cropperRef = useRef(null);
    const fileInputRef = useRef(null);
    const [form] = Form.useForm();
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (file) {
            const blob = URL.createObjectURL(file);
            setImage(blob);
        }
    }, [file]);

    useEffect(() => {
        if (imgSrc) {
            setImage(imgSrc);
        }
    }, [imgSrc]);

    useEffect(() => {
        form.setFieldsValue({u_user_value});
    }, [form, u_user_value]);

    const defaultCoordinates = ({ imageSize }) => {
        const size = Math.min(imageSize.width, imageSize.height);
        return {
            left: (imageSize.width - size) / 2,
            top: (imageSize.height - size) / 2,
            width: size,
            height: size,
        };
    };

    const handleRotate = (angle) => {
        if (!cropperRef.current) return;
        cropperRef.current.rotateImage(angle);
    };

    const handleFileInputChange = (event) => {
        forEach(event.target.files, (file) => {
            const blob = URL.createObjectURL(file);
            setImage(blob);
        });
        event.target.value = '';
    };

    const handleOk = () => {
        if (!cropperRef.current) return;

        form.validateFields()
        .then((values) => {
            const u_photo = cropperRef.current.getCanvas({maxWidth: maxPhotoSize, maxHeight: maxPhotoSize})?.toDataURL('image/jpeg', 0.7);
            const u_photo_s = cropperRef.current.getCanvas({maxWidth: maxSmallPhotoSize, maxHeight: maxSmallPhotoSize})?.toDataURL('image/jpeg', 0.7);
            
            onSave({
                filename, u_photo, u_photo_s,
                ...values
            });
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
            if (Array.isArray(info.errorFields) && info.errorFields.length > 0) {
                var field = form.getFieldInstance(info.errorFields[0].name[0]);
                if (field) field.focus();
            }
        });
    };

    return (
        <Modal centered width={800} destroyOnClose={true} className='edit-photo-modal' open={show} onCancel={onClose}
            title={<FormattedMessage id='edit_photo' />}
            footer={[
                showUpload ? 
                <Tooltip key='upload' placement='bottom' title={<FormattedMessage id='upload' />}>
                    <Button  type='primary' shape='circle' icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} />
                </Tooltip> : null,
                <Button key='rotate-left' type='primary' shape='circle' icon={<RotateLeftOutlined />} onClick={() => handleRotate(-90)} />,
                <Button key='rotate-right' type='primary' shape='circle' icon={<RotateRightOutlined />} onClick={() => handleRotate(90)} />,
                <div key='space' className='space'></div>,
                <Button key='cancel' onClick={onClose}>
                    <FormattedMessage id='cancel' />
                </Button>,
                <Button key='ok' type='primary' onClick={handleOk}>
                    <FormattedMessage id='ok' />
                </Button>
            ]}
        >
            <Form form={form} labelWrap>
                {
                    u_user_value &&
                    <Form.Item name='u_user_value' label={<FormattedMessage id='file_name' />} rules={[{required: true, message: <FormattedMessage id='required_field' />}]}>
                        <Input />
                    </Form.Item>
                }
            </Form>

            <input ref={fileInputRef} type="file" accept="image/*" style={{display: 'none'}} onChange={handleFileInputChange} />
            
            <Cropper ref={cropperRef} className='edit-photo-cropper' src={image} minWidth={120} minHeight={120} onError={() => onError(filename)}
                stencilComponent={CircleStencil} defaultCoordinates={defaultCoordinates}
            />
        </Modal>
    );
};
EditPhoto.defaultProps = {
    show: false,
    file: null,
    imgSrc: null,
    filename: '',
    u_user_value: '',
    showUpload: false,
    onSave: function({filename, u_user_value, u_photo, u_photo_s}) {},
    onError: function(filename) {},
    onClose: function() {}
};